import React from "react"
import styled from "styled-components"
import { motion } from "framer-motion"
import { rem } from "../core/utils"
import Button from "./Button"
import { LanguageContext } from "../core/context/language-context"

const MessageWrapper = styled(motion.div)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  position: relative;

  p {
    color: #e8e8e8;
    font-size: ${rem(20)};
    margin-top: 0;
    margin-bottom: 1em;
    text-align: center;
  }

  p + button {
    margin-top: 0;
  }

  img {
    display: block;
    width: ${rem(40)};
    height: ${rem(40)};
  }
`

export default function FormErrorMessage({ onChange }) {
  const { tulkojums } = React.useContext(LanguageContext)
  return (
    <MessageWrapper initial={{ opacity: 0 }} animate={{ opacity: 1 }}>
      <p>{tulkojums("notikusi_kluda")}</p>
      <Button
        onClick={() => {
          onChange && onChange()
        }}
      >
        {tulkojums("meginat_velreiz")}
      </Button>
    </MessageWrapper>
  )
}
