import React from "react"
import styled from "styled-components"
import { motion } from "framer-motion"
import { rem } from "../core/utils"

const StyledProductEmpty = styled(motion.li)`
  background-color: #1a1a1a;
  border: 1px solid #232323;
  box-shadow: 0px 2px 6px rgba(45, 45, 45, 0.15);
  border-radius: ${rem(7)};
  color: #3d3d3d;
  display: flex;
  margin: ${rem(7)};
  min-width: ${rem(204)};
  height: ${rem(144)};
  flex: 0 1 auto;

  > span {
    display: inline-block;
    margin: auto;
  }
`

const variants = {
  initial: {
    opacity: 0,
  },
  enter: delay => ({
    opacity: 1,
    transition: { delay: 0.1 * delay },
  }),
}

export default function ProductEmpty({ index }) {
  return (
    <StyledProductEmpty
      initial="initial"
      animate="enter"
      variants={variants}
      custom={index}
    >
      <span>{index}</span>
    </StyledProductEmpty>
  )
}
