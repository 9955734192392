import React from "react"
import styled from "styled-components"
import theme from "../core/theme"
import { rem } from "../core/utils"

const StyledLogo = styled.svg`
  width: ${rem(108)};
  height: ${rem(35)};

  @media (min-width: ${rem(theme.screens.sm)}) {
    width: ${rem(124)};
    height: ${rem(40)};
  }
`

const TypePath = styled.path`
  fill: ${({ variant, theme }) =>
    variant === "dark" || (theme && theme.name === "dark") ? "#000" : "#FFF"};
`

export default React.memo(function Logo({ variant }) {
  return (
    <StyledLogo
      width="124"
      height="40"
      fill="none"
      viewBox="0 0 124 40"
      xmlns="http://www.w3.org/2000/svg"
    >
      <TypePath
        variant={variant}
        d="M5.853 8.134H0v31.83h5.853V8.135zM10.558 16.127h5.35v2.183c1.15-2.148 4.13-2.958 6.428-2.958 2.514 0 4.956 1.021 6.5 2.958.216.211.395.457.575.74a2.62 2.62 0 01.61-.74c1.724-2.043 4.49-2.958 7.11-2.958 2.873 0 5.495.81 7.434 2.958 2.227 2.359 2.514 5.176 2.514 8.274V40h-5.854V27.289c0-1.374-.035-3.345-.861-4.578-.755-1.127-2.227-1.76-3.663-1.76-1.616 0-3.16.704-4.058 2.077-1.006 1.55-.934 3.873-.934 5.599V40h-5.817V27.289c0-1.55 0-3.029-.934-4.402-.826-1.232-2.334-1.936-3.807-1.936-4.668 0-4.74 4.295-4.74 7.676V40h-5.853V16.127zM51.424 8.134h5.854v7.993h5.027v5.07h-5.027V40h-5.854V8.134z"
      />
      <path
        d="M112.221 30.035c-.898 0-1.723-.282-2.262-.774-.216-.212-5.207-4.895-7.11-6.69-.647-.6-1.006-1.409-.97-2.254.036-.845.467-1.62 1.185-2.218l9.768-6.937c1.005-.669 2.047-1.021 2.98-1.021 1.58 0 2.909 1.021 3.304 2.535l2.514 10.211c.251 1.092-.359 2.5-1.329 3.064l-6.536 3.697a3.128 3.128 0 01-1.544.387zm11.671-7.676l-2.514-10.211c-.646-2.535-2.872-4.26-5.53-4.26-1.4 0-2.909.492-4.309 1.408l-9.84 6.972-.036.035c-1.292.986-2.01 2.359-2.082 3.873-.072 1.479.538 2.958 1.687 4.014 1.94 1.796 7.039 6.62 7.111 6.655.969.88 2.37 1.409 3.842 1.409.97 0 1.904-.247 2.658-.67l6.535-3.697c1.904-1.056 2.981-3.486 2.478-5.528z"
        fill="#9D2235"
      />
      <path
        d="M105.829 19.753a2.52 2.52 0 01-.897-.14l-5.926-1.866c-1.472-.458-2.729-2.184-2.729-3.698V5.211c0-.95.251-1.725.718-2.218.431-.458 1.006-.704 1.724-.704.467 0 .97.105 1.508.317l10.271 4.049c1.077.422 1.831 1.197 2.19 2.183.359.986.252 2.078-.323 3.063l-3.519 6.233c-.539.95-1.76 1.62-3.017 1.62zm9.05-11.69c-.575-1.584-1.832-2.852-3.519-3.52L101.089.492C100.299.176 99.473 0 98.719 0c-2.801 0-4.74 2.148-4.74 5.211v8.873c0 2.5 1.903 5.07 4.345 5.846l5.925 1.866c.503.176 1.042.246 1.616.246 2.083 0 4.094-1.127 5.064-2.817l3.519-6.232c.826-1.585 1.005-3.31.431-4.93z"
        fill="#C8102E"
      />
      <path
        d="M85.216 18.063l16.052-5.88c.36-.105.683-.176 1.006-.176 1.113 0 1.903.704 2.155 1.866l1.005 4.789 1.221 5.845c.072.387.036.704-.108.845-.143.141-.359.176-.538.176-.108 0-.252 0-.395-.035L86.15 21.514c-1.472-.282-2.442-.95-2.514-1.725-.108-.67.539-1.373 1.58-1.726zm23.666 5.986l-2.227-10.633c-.467-2.184-2.226-3.662-4.417-3.662-.575 0-1.185.105-1.796.316L84.39 15.915c-2.047.74-3.232 2.325-3.088 4.05.072.88.503 1.725 1.257 2.394.754.67 1.795 1.127 3.088 1.373l19.464 3.98c.287.07.574.105.862.105.969 0 1.795-.352 2.334-1.021.61-.704.79-1.69.575-2.747z"
        fill="#FA4616"
      />
      <path
        d="M73.007 23.169c-1.114.282-1.868.352-2.19-.035-.252-.282-.216-.88.394-2.043l6.536-13.345c.503-1.02 1.185-1.584 1.903-1.584.61 0 1.257.387 1.868 1.127 1.939 2.394 7.254 9.014 7.29 9.049.466.599.646 1.162.466 1.655-.143.493-.646.845-1.364 1.056l-14.903 4.12zm17.596-8.204c-.036-.07-5.35-6.655-7.29-9.05-1.041-1.302-2.334-2.007-3.663-2.007-1.652 0-3.088 1.057-3.986 2.853l-6.572 13.345c-1.077 2.183-.718 3.52-.251 4.225.539.845 1.544 1.303 2.801 1.303.61 0 1.293-.106 2.011-.317l14.903-4.12c1.472-.422 2.514-1.338 2.909-2.535.395-1.162.108-2.535-.862-3.697z"
        fill="#FF6A13"
      />
    </StyledLogo>
  )
})
