import React from "react"
import { graphql, useStaticQuery, navigate } from "gatsby"
// import { useHistory } from "react-router-dom"
import { LanguageContext } from "../core/context/language-context"
import Dropdown from "./Dropdown"

export const iestatijumiQuery = graphql`
  query Iestatijumi {
    allStrapiIestatijumi {
      edges {
        node {
          valodas {
            citas {
              id
              teksts
            }
            noklusejuma {
              id
              teksts
            }
          }
        }
      }
    }
  }
`

export default function LanguageToggle() {
  const { changeLanguage, contextLang } = React.useContext(LanguageContext)
  // const history = useHistory()

  const data = useStaticQuery(iestatijumiQuery)
  const doc = data.allStrapiIestatijumi.edges.slice(0, 1).pop()
  if (!doc) return null

  // const languages = [
  //   {
  //     value: "lv",
  //     text: "Lat",
  //     selected: true,
  //   },
  //   {
  //     value: "ru",
  //     text: "Rus",
  //   },
  // ]

  const { valodas } = doc.node
  const { noklusejuma, citas } = valodas
  const languages = [
    {
      value: noklusejuma.id,
      text: noklusejuma.teksts,
      selected: !contextLang,
    },
    ...citas.map(valoda => ({
      value: valoda.id,
      text: valoda.teksts,
      selected: contextLang && contextLang === valoda.id,
    })),
  ]

  return (
    <>
      <Dropdown
        options={languages}
        onChange={value => {
          changeLanguage(value)
          // history.push({
          //   pathname: value === noklusejuma.id ? `/` : `/${value}`,
          // })
          navigate(value === noklusejuma.id ? `/` : `/${value}`)
        }}
      />
    </>
  )
}
