import React from "react"
import styled, { ThemeProvider } from "styled-components"
import { motion, AnimatePresence } from "framer-motion"
import { useInView } from "react-intersection-observer"
import { graphql, useStaticQuery } from "gatsby"
import { listReset, buttonReset } from "../../core/reset"
import theme from "../../core/theme"
import { rem } from "../../core/utils"
import { translate } from "../../core/helpers"
import { LanguageContext } from "../../core/context/language-context"
import { DragCardDesktop, DragCardMobile } from "../DragCard"
import DropZone from "../DropZone"
import RichText from "../RichText"

const Section = styled.section`
  background-color: #f6f6f6;
  margin: ${rem(16)} auto;
  padding: ${rem(32)} ${rem(24)};

  @media (min-width: ${rem(theme.screens.sm)}) {
    background-color: ${theme.colors.variants.default.background.highlight};
    margin: ${rem(64)} auto;
    max-width: ${rem(1280)};
    padding: ${rem(56)};
  }

  @media (min-width: ${rem(1280)}) {
    border-radius: ${rem(8)};
  }
`

const Container = styled.div`
  margin-left: auto;
  margin-right: auto;
  /* max-width: ${rem(1080)}; */
`

const Title = styled.h2`
  color: ${({ theme }) => theme.text.heading};
  font-size: ${rem(32)};
  font-weight: 400;
  line-height: 1.2;
  margin-top: 0;
  margin-bottom: 0.75em;
`

const Description = styled.p`
  font-size: ${rem(16)};
  line-height: 1.5;
  margin-top: 0;
  margin-bottom: 2em;
  max-width: 60ch;
`

const CardList = styled(motion.ul)`
  ${listReset};

  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin: ${rem(-12)};
`

const CardListWrapper = styled.div`
  position: relative;
`

const StyledStatusBarContainer = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 10;
  width: 100%;
  overflow: hidden;
`

const StyledStatusBar = styled(motion.div)`
  background-color: #1d1d1d;
  color: #e8e8e8;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: ${rem(20)};
  line-height: 1.2;
  padding: 0 ${rem(32)};

  > div {
    display: flex;
    align-items: center;
  }

  > button {
    transform: translateX(${rem(24)});
  }
`

const StatusEmojiWrapper = styled.div`
  position: relative;
  margin-left: ${rem(8)};
  width: ${rem(40)};
  height: ${rem(40)};

  img {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`

const StyledExpandButton = styled.button.attrs({
  type: "button",
})`
  ${buttonReset};

  cursor: pointer;
  display: flex;
  justify-content: center;

  color: #878787;
  padding: 0.5em;

  &:focus {
    outline: 0;
  }
`

function ExpandButton({ onClick }) {
  return (
    <StyledExpandButton onClick={onClick} title="Apskatīt komplektu">
      <svg
        width="48"
        height="48"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M28 11h9v9M20 37h-9v-9M37 11L27 21M11 37l10-10"
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </StyledExpandButton>
  )
}

function getStatusEmojiForCount(count) {
  switch (count) {
    case 2:
      return "/emoji/status/smiles-2_40@3.png"
    case 3:
      return "/emoji/status/smiles-3_40@3.png"
    case 4:
      return "/emoji/status/smiles-4_40@3.png"
    case 5:
      return "/emoji/status/smiles-5_40@3.png"
    case 1:
    default:
      return "/emoji/status/smiles-1_40@3.png"
  }
}

const statusBarVariants = {
  initial: {
    y: "100%",
  },
  enter: {
    y: "0%",
    transition: { delay: 0.2, type: "spring", damping: 50, stiffness: 250 },
  },
  exit: {
    y: "100%",
  },
}

function MobileStatusBar({ grozs, tulkojums, hideStatus, scrollToDropZone }) {
  const [showStatus, setShowStatus] = React.useState(false)
  const count = grozs.length

  React.useEffect(() => {
    setShowStatus(count > 0)
  }, [count])

  return (
    <StyledStatusBarContainer onClick={scrollToDropZone}>
      <StyledStatusBar
        initial="initial"
        animate={hideStatus ? "exit" : showStatus ? "enter" : "intial"}
        exit="exit"
        variants={statusBarVariants}
      >
        <div>
          {`${tulkojums("komplekts")} (${count}/5)`}
          <StatusEmojiWrapper>
            <img src={getStatusEmojiForCount(count)} alt="" />
          </StatusEmojiWrapper>
        </div>
        <ExpandButton />
      </StyledStatusBar>
    </StyledStatusBarContainer>
  )
}

const MessageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: ${rem(44)};
  margin-bottom: ${rem(36)};

  h3 {
    font-size: ${rem(20)};
    font-weight: 400;
    margin-top: 0;
    margin-bottom: ${rem(12)};
    text-align: center;
  }

  @media (min-width: ${rem(theme.screens.sm)}) {
    display: none;
  }
`

function ArrowIcon() {
  return (
    <svg width="48" height="48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M24 13v22M35 24L24 35 13 24"
        stroke="#C8102E"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export const kalkulatoraSekcijaQuery = graphql`
  query KalkulatoraSekcija {
    allStrapiKalkulatoraSekcija {
      edges {
        node {
          virsraksts__lv
          # virsraksts__ru
          virsraksts__en
          apraksts__lv
          svarigi__lv
          # apraksts__ru
          apraksts__en
          svarigi__en
          liguma_kopsavilkums {
            virsraksts__lv
            # virsraksts__ru
            virsraksts__en
            fails {
              publicURL
            }
          }
          papildinformacija__lv
          # papildinformacija__ru
          papildinformacija__en
        }
      }
    }

    ikona_svarigi: file(
      relativePath: { eq: "ikonas/ikona-svarigi_40@2x.png" }
    ) {
      childImageSharp {
        fixed(width: 40, height: 40) {
          ...GatsbyImageSharpFixed_noBase64
        }
      }
    }
  }
`

export default function Komplektetajs({ innerRef, tarifi }) {
  const { language, tulkojums } = React.useContext(LanguageContext)

  const dropRef = React.useRef(null)
  const [dropActive, setDropActive] = React.useState(false)

  const [izvele] = React.useState(
    () => tarifi.map(({ node }) => ({ ...node })),
    [tarifi]
  )

  const [grozs, setGrozs] = React.useState([])
  const dropCount = React.useRef(0)

  // React.useEffect(() => {
  //   const newIzvele = izvele.map(tarifs => {
  //     const uid = `${tarifs.id}_${dropCount.current}`
  //     return { ...tarifs, uid }
  //   })
  //   setIzvele(newIzvele)
  // }, [grozs])

  function onProductDrop(id) {
    const tarifs = izvele.find(tarifs => tarifs.id === id)
    const uid = `${tarifs.id}_${dropCount.current}`
    const newGrozs = [...grozs, { ...tarifs, uid }]
    dropCount.current += 1
    setGrozs(newGrozs)

    // Google Tag Manager event
    window.dataLayer &&
      window.dataLayer.push({
        event: "familyServiceAdded",
        service: tarifs.nosaukums,
      })

    // const newIzvele = izvele.filter(tarifs => tarifs.id !== id)
    // setIzvele(newIzvele)
  }

  function onProductRemove(uid) {
    const newGrozs = grozs.filter(tarifs => tarifs.uid !== uid)
    setGrozs(newGrozs)

    // const tarifs = grozs.find(tarifs => tarifs.id === id)
    // const newIzvele = [...izvele, tarifs]
    // setIzvele(newIzvele)
  }

  function onProductRemoveById(id) {
    let newGrozs = [...grozs].reverse()
    const index = newGrozs.findIndex(t => t.id === id)
    const lastIndex = newGrozs.length - index - 1
    newGrozs.reverse()
    newGrozs.splice(lastIndex, 1)
    setGrozs(newGrozs)
  }

  function onComplete() {
    setGrozs([])
  }

  // FIXME: Vai šim nevajadzētu būt iekļautam React.useEffect?
  // Daļēji saistīts ar SvarigiZinatSekcija Mac gadījumu.
  let variant = "mobile"
  let DragCard = DragCardMobile
  let dragCardProps = {
    tulkojums,
  }
  if (typeof window !== "undefined") {
    const minSmallScreen = window.matchMedia(
      `(min-width: ${rem(theme.screens.sm)})`
    )
    if (minSmallScreen && minSmallScreen.matches) {
      variant = "desktop"

      DragCard = DragCardDesktop
      dragCardProps = {
        ...dragCardProps,
        dropRef: dropRef,
        onDropChange: setDropActive,
      }
    }
  }

  const [inViewRef, dropZoneInView] = useInView({
    triggerOnce: false,
  })
  const setRefs = React.useCallback(
    node => {
      dropRef.current = node
      inViewRef(node)
    },
    [inViewRef]
  )

  const data = useStaticQuery(kalkulatoraSekcijaQuery)
  const doc = data.allStrapiKalkulatoraSekcija.edges.slice(0, 1).pop()
  if (!doc) return

  const sekcija = translate(doc.node, language)

  return (
    <ThemeProvider theme={theme.colors.variants.default}>
      <Section ref={innerRef}>
        <Container>
          <Title
            dangerouslySetInnerHTML={{
              __html: sekcija.virsraksts
                .replace("\n", "<br />")
                .replace(/\*\*(.+)\*\*/, "<strong>$1</strong>"),
            }}
          />
          {/* <Description>{sekcija.apraksts}</Description> */}
          <Description
            dangerouslySetInnerHTML={{
              __html: sekcija.apraksts,
            }}
          />
          {/* <Description><RichText render={sekcija.apraksts} /></Description> */}

          {sekcija.svarigi ? (
            <ImportantMessage>
              <img
                src={data.ikona_svarigi.childImageSharp.fixed.src}
                alt=""
                width="28"
                height="28"
              />
              <p
                dangerouslySetInnerHTML={{
                  __html: sekcija.svarigi?.replace(
                    /\*\*(.+)\*\*/,
                    "<strong>$1</strong>"
                  ),
                }}
              />
            </ImportantMessage>
          ) : null}

          <MessageWrapper>
            <h3>{tulkojums("pievieno_savam_komplektam")}</h3>
            <ArrowIcon />
          </MessageWrapper>

          <CardListWrapper>
            <CardList>
              <AnimatePresence>
                {izvele.map((tarifs, index) => {
                  const vienadi = grozs.filter(t => tarifs.id === t.id)
                  return (
                    <DragCard
                      key={tarifs.id}
                      {...tarifs}
                      index={index}
                      count={vienadi.length}
                      onProductDrop={onProductDrop}
                      onProductRemove={onProductRemoveById}
                      /* Maks. 5 pakalpojumi grozā */
                      disabled={grozs.length > 4}
                      {...dragCardProps}
                    />
                  )
                })}
              </AnimatePresence>
            </CardList>

            {variant === "mobile" && (
              <MobileStatusBar
                grozs={grozs}
                tulkojums={tulkojums}
                hideStatus={dropZoneInView}
                scrollToDropZone={() => {
                  dropRef.current.scrollIntoView({ behavior: "smooth" })
                }}
              />
            )}
          </CardListWrapper>

          <DropZone
            innerRef={setRefs}
            isActive={dropActive}
            tarifi={grozs}
            tulkojums={tulkojums}
            hidden={variant === "mobile" && grozs.length === 0}
            onProductRemove={onProductRemove}
            onComplete={onComplete}
            variant={variant}
          />

          {sekcija["liguma_kopsavilkums"] && (
            <DocumentGroup>
              <p>{`${tulkojums("ligumu_kopsavilkumi")}:`}</p>
              <ul>
                {sekcija["liguma_kopsavilkums"].map(kopsavilkums => {
                  const { virsraksts, fails } = translate(
                    kopsavilkums,
                    language
                  )
                  return (
                    <li key={virsraksts}>
                      <a
                        href={fails.publicURL}
                        target="_blank"
                        rel="noreferrer"
                      >
                        {virsraksts}
                      </a>
                    </li>
                  )
                })}
              </ul>
            </DocumentGroup>
          )}

          {sekcija["papildinformacija"] && (
            <RichText render={sekcija["papildinformacija"]} />
          )}
        </Container>
      </Section>
    </ThemeProvider>
  )
}

const DocumentGroup = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 0.5em;

  ul {
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    margin: -0.5em -0.75em;
    padding-left: 0;
  }

  li {
    margin: 0.5em 0.75em;
  }

  a {
    color: ${theme.colors.accent};
  }

  @media (min-width: ${rem(theme.screens.sm)}) {
    flex-direction: row;
    align-items: center;

    ul {
      align-items: center;
      padding-left: 1.5em;
    }
  }
`

const ImportantMessage = styled.div`
  display: flex;
  align-items: center;
  margin-top: -1rem;
  margin-bottom: 1rem;

  img {
    margin-right: 0.75em;
  }

  a {
    color: ${theme.colors.accent};
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
`
