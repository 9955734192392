import React from "react"
import styled from "styled-components"
import theme from "../core/theme"
import { rem } from "../core/utils"

const CheckboxWrapper = styled.div`
  display: flex;
  align-items: center;
`

const StyledCheckbox = styled.input.attrs({
  type: "checkbox",
})`
  -webkit-appearance: none;

  border: 1px solid ${theme.colors.accent};
  border-radius: ${rem(3)};
  cursor: pointer;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
  width: ${rem(20)};
  height: ${rem(20)};

  & + label {
    font: inherit;
    font-size: ${rem(13)};
    margin-left: ${rem(8)};
    padding: 0.5em;
    user-select: none;

    &:hover {
      color: ${({ theme }) => theme.text.heading};
    }
  }

  &:focus {
    outline: 0;
  }

  &:checked {
    position: relative;

    &::after {
      background-color: ${theme.colors.accent};
      background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACIAAAAZCAYAAABU+vysAAAACXBIWXMAACE4AAAhOAFFljFgAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAD7SURBVHgBzZVtEYMwDIYzB5NQCZMyCTgYDsABSJgDJCABCUiYhHfp0d2VXDvGV7Lnjjv+pHn6kYToT7iQMQAKsoYlKgTIiljCTITz3jHnSdpwUsffK5IY+LuSJkFijCT8vyNN/K7NJYJIL97FjbThpI2QKEkbWaZMTdpw0oeQaJcCPq95POru/DpCov8lqI4CfI3faQdIl+lyr0gEbn5Q2NsrMjIVrQBTrxjE6TpaS0amWRHfidjtVxx2JRfslu4YZ5UpL9SKhYfcMePsXoF5NQGJhwetkf5NBomRTmfCCQqR0P+XsJimmDrliDQ6EpGMy8iYjHQpc8hIfwMrOKnH1NmYZwAAAABJRU5ErkJggg==");
      background-size: ${rem(10)} ${rem(7)};
      background-position: center center;
      background-repeat: no-repeat;
      border-color: ${theme.colors.accent};
      border-radius: ${rem(3)};
      position: absolute;
      top: -1px;
      left: -1px;

      content: "";
      display: block;
      width: ${rem(20)};
      height: ${rem(20)};
    }
  }

  &:disabled {
    border-color: ${({ theme }) => theme.text.body};
    opacity: 0.5;

    pointer-events: none;

    &::after {
      background-color: ${({ theme }) => theme.text.body};
      border-color: ${({ theme }) => theme.text.body};
    }
  }
`

export default function Checkbox({ id, defaultChecked, label, name, onChange, disabled }) {
  return (
    <CheckboxWrapper>
      <StyledCheckbox
        id={id}
        name={name}
        defaultChecked={defaultChecked}
        onChange={onChange}
        disabled={disabled}
      />
      {label && <label htmlFor={id}>{label}</label>}
    </CheckboxWrapper>
  )
}