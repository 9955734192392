import React from "react"
import styled from "styled-components"
import { motion } from "framer-motion"
import Img from "gatsby-image"
import theme from "../core/theme"
import { rem } from "../core/utils"
import Cena from "./Cena"

const StyledKartina = styled(motion.li)`
  background: #fff;
  border: 1px solid #e8e8e8;
  border-radius: ${rem(5)};
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: 0px 2px 16px rgba(45, 45, 45, 0.1);
  overflow: hidden;
`

const ImageGroup = styled.div`
  border-top-left-radius: ${rem(5)};
  border-top-right-radius: ${rem(5)};
  flex: 0 0 auto;
  margin: -1px;
  height: ${rem(108)};
  width: 100%;

  > img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center top;
  }

  > .gatsby-image-wrapper {
    width: 100%;
    height: 100%;

    > div {
      height: 100%;
      padding-bottom: 0;
    }

    img {
      object-position: center top;
    }
  }
`

const ContentGroup = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: ${rem(32)};
`

const TopGroup = styled.div`
  margin-bottom: ${rem(20)};
`

const BottomGroup = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: auto;
`

const Virsraksts = styled.h4`
  color: ${theme.colors.variants.default.text.heading};
  font-size: ${rem(20)};
  line-height: 1.3;
  margin-top: 0;
  margin-bottom: 0.5em;
  text-align: center;
`

const Teksts = styled.p`
  line-height: 1.3;
  margin: ${rem(10)};
  margin-bottom: 0;
  text-align: center;
`

const CenaWrapper = styled.div`
  > * {
    color: ${theme.colors.variants.default.text.heading};
    font-size: ${rem(32)};
  }
`

const StyledLink = styled.a`
  color: ${theme.colors.accent};
  display: inline-flex;
  align-items: center;
  margin: -0.5em;
  padding: 0.5em;
  text-decoration: none;

  > svg {
    display: block;
    margin-left: 0.35em;
  }

  &:hover {
    text-decoration: underline;
  }
`

function ExternalIcon() {
  return (
    <svg width="20" height="20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M15 10.833v5a1.666 1.666 0 01-1.667 1.667H4.167A1.667 1.667 0 012.5 15.833V6.667A1.667 1.667 0 014.167 5h5M12.5 2.5h5v5M8.333 11.667L17.5 2.5"
        stroke="#C8102E"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

function ExternalLink({ label, to }) {
  return (
    <StyledLink href={to} target="_blank">
      {label} <ExternalIcon />
    </StyledLink>
  )
}

export default function TarifaKartina({
  className,
  style,
  data,
  index,
  animate,
  tulkojums,
}) {
  const { bilde, nosaukums, apraksts, pilna_cena, vairak_saite } = data
  return (
    <StyledKartina
      className={className}
      style={style}
      initial="initial"
      animate={animate ? "enter" : "initial"}
      variants={theme.motion.contentVariants}
      custom={{ delay: 0.1 * index }}
    >
      <ImageGroup>
        {bilde.childImageSharp ? (
          <Img fluid={bilde.childImageSharp.fluid} alt="" />
        ) : (
          <img src={bilde} alt="" />
        )}
      </ImageGroup>

      <ContentGroup>
        <TopGroup>
          <Virsraksts>{nosaukums}</Virsraksts>
          <Teksts>{apraksts}</Teksts>
        </TopGroup>

        <BottomGroup>
          <CenaWrapper>
            <Cena cena={pilna_cena} tulkojums={tulkojums} />
          </CenaWrapper>

          <ExternalLink label={tulkojums("vairak")} to={vairak_saite} />
        </BottomGroup>
      </ContentGroup>
    </StyledKartina>
  )
}
