import React from "react"
import styled, { css } from "styled-components"
import { motion } from "framer-motion"
import Img from "gatsby-image"
import theme from "../core/theme"
import { rem } from "../core/utils"

const StyledDragCard = styled(motion.li)`
  background-color: ${theme.colors.white};
  border: 1px solid #e8e8e8;
  border-radius: ${rem(7)};
  box-shadow: 0px 2px 16px rgba(45, 45, 45, 0.1);
  margin: ${rem(12)};
  width: 100%;
  max-width: ${rem(400)};

  display: flex;
  flex-direction: column;
  flex: 0 1 auto;

  @media (min-width: ${rem(theme.screens.sm)}) {
    cursor: grab;
    flex-direction: row;
    transition: box-shadow 200ms ease-in-out;
    width: auto;
    will-change: transform;
    user-select: none;

    ${({ isPointerDown }) =>
      isPointerDown &&
      css`
        box-shadow: 0px 2px 32px rgba(45, 45, 45, 0.1);
        cursor: grabbing;
      `};
  }
`

const EmojiGroup = styled.div`
  flex: 0 0 auto;
  display: flex;
  border-bottom: 1px solid #e8e8e8;
  padding: ${rem(8)};

  > span[role="img"] {
    display: block;
    font-size: ${rem(26)};
    line-height: 1;
    margin: auto;
  }

  > img,
  > .gatsby-image-wrapper {
    display: block;
    width: ${rem(40)};
    height: ${rem(40)};
    margin: auto;

    user-select: none;
    -moz-user-select: none;
    -webkit-user-drag: none;
    -webkit-user-select: none;
    -ms-user-select: none;
  }

  @media (min-width: ${rem(theme.screens.sm)}) {
    border-right: 1px solid #e8e8e8;
    border-bottom: 0;
  }
`

const ContentGroup = styled.div`
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: ${rem(16)} ${rem(18)};
  text-align: center;

  @media (min-width: ${rem(theme.screens.sm)}) {
    padding: ${rem(24)} ${rem(18)};
    text-align: left;
  }
`

const Name = styled.h4`
  color: ${({ theme }) => theme.text.heading};
  font-size: ${rem(20)};
  line-height: 1.2;
  margin-top: 0;
  margin-bottom: 0;
`

const Description = styled.p`
  line-height: 1.2;
  margin-top: 0;
  margin-bottom: 0;

  > span {
    display: inline-flex;
    align-items: center;
    height: 1.2em;
    vertical-align: top;
  }
`

function InfinityIcon({ tulkojums }) {
  return (
    <span role="img" aria-label={tulkojums("neierobezoti")}>
      <svg
        width="24"
        height="14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M12 7s2.338 3.5 5.065 3.5a3.51 3.51 0 002.48-1.025 3.497 3.497 0 000-4.95 3.51 3.51 0 00-2.48-1.025c-1.477 0-2.84 1.027-3.78 1.968M12 7S9.662 3.5 6.935 3.5a3.51 3.51 0 00-2.48 1.025 3.497 3.497 0 000 4.95 3.51 3.51 0 002.48 1.025c1.477 0 2.84-1.027 3.78-1.968"
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </span>
  )
}

const onTop = { zIndex: 1 }
const flat = {
  opacity: 1,
  scale: 1,
  zIndex: 0,
  transition: { delay: 0.3 },
}

function rectContains(a, b) {
  return (
    a.top > b.top &&
    a.top + a.height < b.top + b.height &&
    a.left > b.left &&
    a.left + a.width < b.left + b.width
  )
}

export function DragCardDesktop({
  id,
  nosaukums,
  apraksts_isi,
  neierobezots,
  emoji,
  index,
  dropRef,
  onDropChange,
  onProductDrop,
  tulkojums,
  disabled,
}) {
  const ref = React.useRef(null)
  const [isDragging, setDragging] = React.useState(false)
  const [isPointerDown, setPointerDown] = React.useState(false)
  const [dropRect, setDropRect] = React.useState({})

  const rectContainsRef = React.useRef(false)
  const [dropActive, setDropActive] = React.useState(false)

  React.useEffect(() => {
    onDropChange && onDropChange(dropActive)
  }, [dropActive, onDropChange])

  const initialRef = React.useRef(true)
  let initialAnimateOrFlat = flat
  if (initialRef.current) {
    initialAnimateOrFlat = {
      ...flat,
      opacity: 1,
      scale: 1,
      transition: { delay: 0.1 * index },
    }
    initialRef.current = false
  }

  const disabledProps = disabled
    ? {
        drag: false,
        whileHover: false,
        whileTap: false,

        disabled: true,
        style: { cursor: "auto" },
        title: tulkojums("komplekta_5_pakalpojumus"),
      }
    : {}

  // FIXME: Atgriezšanās animācija: pop-in-place tipa nevis slīdoša

  return (
    <StyledDragCard
      ref={ref}
      initial={{ opacity: 0, scale: 0.8 }}
      animate={isDragging ? onTop : initialAnimateOrFlat}
      exit="exit"
      whileHover={{ scale: 1.03 }}
      whileTap={{ scale: 1.12 }}
      drag
      dragElastic={1}
      dragConstraints={ref}
      onDragStart={() => {
        const dropRect = dropRef.current.getBoundingClientRect()
        setDropRect(dropRect)
        setDragging(true)
      }}
      onDragEnd={() => {
        setDragging(false)

        if (dropActive) {
          onProductDrop(id)
          setDropActive(false)
        }
      }}
      onDrag={() => {
        const rect = ref.current.getBoundingClientRect()
        const contains = rectContains(rect, dropRect)
        if (contains !== rectContainsRef.current) {
          setDropActive(contains)
        }
        rectContainsRef.current = contains
      }}
      onPointerDown={() => setPointerDown(true)}
      onPointerUp={() => setPointerDown(false)}
      isPointerDown={isPointerDown}
      dropActive={dropActive}
      positionTransition={{ damping: 40, stiffness: 160 }}
      {...disabledProps}
    >
      <EmojiGroup>
        {emoji.childImageSharp ? (
          <Img fixed={emoji.childImageSharp.fixed} alt="" />
        ) : (
          <img src={emoji.img} alt="" />
        )}
      </EmojiGroup>

      <ContentGroup>
        <Name>{nosaukums}</Name>
        <Description>
          {neierobezots && (
            <>
              <InfinityIcon tulkojums={tulkojums} />{" "}
            </>
          )}
          {apraksts_isi}
        </Description>
      </ContentGroup>
    </StyledDragCard>
  )
}

const ActionGroup = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: ${rem(12)};
`

const Count = styled.p`
  color: #4e4e4e;
  font-weight: 700;
  margin-top: 0;
  margin-bottom: 0;
`

const StyledAddButton = styled.button.attrs({
  type: "button",
})`
  background-color: #fff;
  border: 1px solid #e8e8e8;
  border-radius: ${rem(5)};
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${rem(40)};
  height: ${rem(40)};
  padding: 0;

  &:disabled {
    pointer-events: none;
    opacity: 0.5;
  }
`

function RemoveButton({ onClick, tulkojums }) {
  return (
    <StyledAddButton onClick={onClick} title={tulkojums("nonemt")}>
      <svg
        width="30"
        height="30"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M7 15h16"
          stroke="#4E4E4E"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </StyledAddButton>
  )
}

function AddButton({ disabled, onClick, tulkojums }) {
  return (
    <StyledAddButton
      disabled={disabled}
      onClick={onClick}
      title={tulkojums("pievienot")}
    >
      <svg
        width="30"
        height="30"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M15 7v16M7 15h16"
          stroke="#4E4E4E"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </StyledAddButton>
  )
}

export function DragCardMobile({
  id,
  nosaukums,
  apraksts_isi,
  neierobezots,
  emoji,
  index,
  count = 0,
  onProductDrop,
  onProductRemove,
  tulkojums,
  disabled,
}) {
  const ref = React.useRef(null)

  const initialRef = React.useRef(true)
  let initialAnimateOrFlat = flat
  if (initialRef.current) {
    initialAnimateOrFlat = {
      ...flat,
      opacity: 1,
      scale: 1,
      transition: { delay: 0.1 * index },
    }
    initialRef.current = false
  }

  const disabledProps = disabled
    ? {
        drag: false,
        whileHover: false,
        whileTap: false,

        disabled: true,
        style: { cursor: "auto" },
        title: tulkojums("komplekta_5_pakalpojumus"),
      }
    : {}

  return (
    <StyledDragCard
      ref={ref}
      initial={{ opacity: 0, scale: 0.8 }}
      animate={initialAnimateOrFlat}
      exit="exit"
      positionTransition={{ damping: 40, stiffness: 160 }}
      {...disabledProps}
    >
      <EmojiGroup>
        {emoji.childImageSharp ? (
          <Img fixed={emoji.childImageSharp.fixed} alt="" />
        ) : (
          <img src={emoji.img} alt="" />
        )}
      </EmojiGroup>

      <ContentGroup>
        <Name>{nosaukums}</Name>
        <Description>
          {neierobezots && (
            <>
              <InfinityIcon tulkojums={tulkojums} />{" "}
            </>
          )}
          {apraksts_isi}
        </Description>

        <ActionGroup>
          {count > 0 ? (
            <>
              <RemoveButton
                onClick={() => {
                  onProductRemove && onProductRemove(id)
                }}
                tulkojums={tulkojums}
              />

              <Count>{`x ${count}`}</Count>
            </>
          ) : (
            <span />
          )}

          <AddButton
            disabled={disabled}
            onClick={() => {
              onProductDrop && onProductDrop(id)
            }}
            tulkojums={tulkojums}
          />
        </ActionGroup>
      </ContentGroup>
    </StyledDragCard>
  )
}
