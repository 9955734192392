import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import styled from "styled-components"
import { motion } from "framer-motion"
import { useInView } from "react-intersection-observer"
import { rem } from "../../core/utils"
import theme from "../../core/theme"
import Button from "../../components/Button"
import { LanguageContext } from "../../core/context/language-context"
import { translate } from "../../core/helpers"

export const piesakiesQuery = graphql`
  query PiesakiesSekcija {
    strapiPiesakiesSekcija {
      virsraksts__lv
      # virsraksts__ru
      virsraksts__en
      # Forma {
      #   virsraksts__lv
      #   virsraksts__ru
      #   virsraksts__en
      #   apstiprinajums__lv
      #   apstiprinajums__ru
      #   apstiprinajums__en
      # }
    }
  }
`

const Section = styled.section`
  background-color: ${theme.colors.variants.default.background.highlight};
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: ${rem(1280)};
  padding: ${rem(48)} ${rem(24)} ${rem(32)};

  > button {
    width: 100%;
  }

  @media (min-width: ${rem(theme.screens.sm)}) {
    background-color: #f6f6f6;
    margin: ${rem(160)} auto;
    padding: 0 ${rem(56)};

    > button {
      width: auto;
    }
  }
`

const Virsraksts = styled.h3`
  color: ${theme.colors.variants.default.text.heading};
  font-size: ${rem(32)};
  font-weight: 400;
  line-height: 1.2;
  margin-top: 0;
  margin-bottom: 0;
  max-width: 25ch;
  text-align: center;

  > span {
    display: inline-block;

    &:first-child {
      font-weight: 700;
    }
  }

  /* @media (min-width: ${rem(theme.screens.sm)}) {
    margin-bottom: 2em;
  } */
`

export default function PiesakiesSekcija({ onPopupOpen }) {
  const { language, tulkojums } = React.useContext(LanguageContext)

  const [ref, inView] = useInView({
    triggerOnce: true,
  })

  const data = useStaticQuery(piesakiesQuery)
  const doc = data.strapiPiesakiesSekcija
  if (!doc) return null

  const sekcija = translate(doc, language)
  const virsraksts = sekcija.virsraksts.split("\n")

  return (
    <Section ref={ref}>
      <Virsraksts>
        <motion.span
          initial="initial"
          animate={inView ? "enter" : "initial"}
          variants={theme.motion.contentVariants}
          custom={{ delay: 0.2 }}
        >
          {virsraksts[0]}
        </motion.span>{" "}
        <motion.span
          initial="initial"
          animate={inView ? "enter" : "initial"}
          variants={theme.motion.contentVariants}
          custom={{ delay: 0.4 }}
        >
          {virsraksts[1]}
        </motion.span>
      </Virsraksts>

      <motion.div
        initial="initial"
        animate={inView ? "enter" : "initial"}
        variants={theme.motion.contentVariants}
        custom={{ delay: 0.8 }}
      >
        <Button high onClick={onPopupOpen}>
          {tulkojums("pieteikties")}
        </Button>
      </motion.div>
    </Section>
  )
}
