import React from "react"
import styled from "styled-components"
import { rem } from "../core/utils"
import theme from "../core/theme"

const StyledInput = styled.input`
  background-color: #1d1d1d;
  border: 1px solid #323232;
  border-radius: ${rem(5)};
  color: #bababa;
  font-family: inherit;
  font-size: ${rem(16)};
  padding: ${rem(14)} ${rem(16)};
  width: 100%;

  ::placeholder {
    color: #606060;
  }

  ::-ms-input-placeholder {
    color: #606060;
  }

  &:focus {
    border: 1px solid #bababa;
    outline: 0;
  }

  &:invalid,
  &.state--error {
    border: 1px solid ${theme.colors.accent};
  }
`

export default function Input({
  name,
  onChange,
  placeholderText = "",
  type = "text",
}) {
  const validationProps = type === "tel" ? { pattern: "[0-9]{8}" } : {}
  return (
    <StyledInput
      name={name}
      onChange={event => {
        onChange && onChange(event.target.value)
      }}
      placeholder={placeholderText}
      spellcheck="false"
      type={type}
      {...validationProps}
    />
  )
}
