import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import IkonuSekcija from "./IkonuSekcija"

// export const ieguvumiQuery = graphql`
//   query {
//     allDataJson(filter: { ieguvumi: { id: { eq: "ieguvumi" } } }) {
//       edges {
//         node {
//           ieguvumi {
//             ieraksti {
//               ikona {
//                 id
//               }
//               virsraksts {
//                 html
//               }
//             }
//           }
//         }
//       }
//     }
//     ikona_heart: file(relativePath: { eq: "ikonas/heart_56@3x.png" }) {
//       childImageSharp {
//         fixed(width: 56, height: 56) {
//           ...GatsbyImageSharpFixed_noBase64
//         }
//       }
//     }
//     ikona_bill: file(relativePath: { eq: "ikonas/bill_56@3x.png" }) {
//       childImageSharp {
//         fixed(width: 56, height: 56) {
//           ...GatsbyImageSharpFixed_noBase64
//         }
//       }
//     }
//     ikona_sun: file(relativePath: { eq: "ikonas/sun_56@3x.png" }) {
//       childImageSharp {
//         fixed(width: 56, height: 56) {
//           ...GatsbyImageSharpFixed_noBase64
//         }
//       }
//     }
//   }
// `

export const ieguvumiSekcijaQuery = graphql`
  query IeguvumiSekcija {
    allStrapiIeguvumiSekcija {
      edges {
        node {
          Ieguvumi {
            virsraksts__lv
            # virsraksts__ru
            virsraksts__en
            ikona {
              childImageSharp {
                fixed(width: 56, height: 56) {
                  ...GatsbyImageSharpFixed_noBase64
                }
              }
            }
          }
        }
      }
    }
  }
`

export default function IeguvumiSekcija() {
  // const queryData = useStaticQuery(ieguvumiQuery)

  // const data = { ...queryData.allDataJson.edges[0].node.ieguvumi }
  // data.ieraksti = data.ieraksti.map(ieraksts => {
  //   const id = ieraksts.ikona && ieraksts.ikona.id
  //   let ikona = queryData[`ikona_${id}`]
  //   if (ikona && ikona.childImageSharp) {
  //     ikona = ikona.childImageSharp
  //   }
  //   return {
  //     ...ieraksts,
  //     ikona: {
  //       ...ieraksts.ikona,
  //       fixed: ikona ? { ...ikona.fixed } : null,
  //     },
  //   }
  // })

  const data = useStaticQuery(ieguvumiSekcijaQuery)
  const doc = data.allStrapiIeguvumiSekcija.edges.slice(0, 1).pop()
  if (!doc) return null

  const ieguvumi = {
    ieraksti: doc.node["Ieguvumi"],
  }
  return <IkonuSekcija data={ieguvumi} withArrow={true} />
}
