import React from "react"
import styled, { css, keyframes } from "styled-components"
import { motion } from "framer-motion"
import { useInView } from "react-intersection-observer"
import Img from "gatsby-image"
import theme from "../../core/theme"
import { rem } from "../../core/utils"
import { listReset } from "../../core/reset"
import { LanguageContext } from "../../core/context/language-context"
import { translate } from "../../core/helpers"

const Section = styled.section`
  background-color: ${theme.colors.variants.default.background.highlight};
  max-width: ${rem(1280)};
  padding: ${rem(48)} ${rem(24)} ${rem(32)};

  @media (min-width: ${rem(theme.screens.sm)}) {
    background-color: #f6f6f6;
    margin: ${rem(96)} auto ${rem(64)};
    padding: 0 ${rem(56)};
  }

  ${({ high }) =>
    high === "true" &&
    css`
      @media (min-width: ${rem(theme.screens.sm)}) {
        margin: ${rem(160)} auto ${rem(120)};
      }
    `};
`

const SekcijasVirsraksts = styled(motion.h3)`
  color: ${theme.colors.variants.default.text.heading};
  font-size: ${rem(32)};
  font-weight: 400;
  line-height: 1.2;
  margin-top: 0;
  margin-bottom: 0.75em;
  text-align: center;

  @media (min-width: ${rem(theme.screens.sm)}) {
    margin-bottom: 2em;
  }
`

const List = styled.ul`
  ${listReset};

  display: flex;
  margin: 0 ${rem(-24)};
  overflow-x: scroll;
  overflow-y: hidden;
  scroll-snap-type: x mandatory;
  scrollbar-width: thin;

  @media (min-width: ${rem(theme.screens.sm)}) {
    justify-content: space-around;
    margin: ${rem(-32)};
    overflow-x: auto;
    scroll-snap-type: none;
  }
`

const StyledIeguvums = styled(motion.li)`
  flex: 1 1 ${(1 / 3) * 100}%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: ${rem(12)};
  min-width: ${rem(220)};
  scroll-snap-align: center;

  @media (min-width: ${rem(theme.screens.sm)}) {
    margin: ${rem(32)};
    min-width: 0;
    scroll-snap-align: none;
  }
`

const Ikona = styled.div`
  background-color: ${theme.colors.white};
  border: 1px solid #e8e8e8;
  border-radius: ${rem(12)};
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${rem(68)};
  height: ${rem(68)};

  > div {
    width: ${rem(32)} !important;
    height: ${rem(32)} !important;

    img {
      object-fit: contain;
    }
  }

  @media (min-width: ${rem(theme.screens.sm)}) {
    width: ${rem(104)};
    height: ${rem(104)};

    > div {
      width: ${rem(56)} !important;
      height: ${rem(56)} !important;
    }
  }

  ${({ variation }) =>
    variation === "ghost" &&
    css`
      background-color: transparent;
      border: none;
      width: ${rem(80)} !important;
      height: ${rem(80)} !important;

      > div {
        width: ${rem(48)} !important;
        height: ${rem(48)} !important;
      }
    `};
`

const Virsraksts = styled.h4`
  color: #2d2d2d;
  font-size: ${rem(20)};
  font-weight: 400;
  line-height: 1.3;
  margin-top: 1em;
  margin-bottom: 0.75em;
  text-align: center;

  > span {
    font-weight: 700;
  }
`

const Teksts = styled.p`
  color: #2d2d2d;
  margin-top: 0;
  margin-bottom: 0;
  text-align: center;
`

export function Ieguvums({ animate, index, data, variation }) {
  const { ikona, virsraksts, teksts } = data

  const ikonaFixed = ikona.childImageSharp
    ? ikona.childImageSharp.fixed
    : ikona.fixed
    ? ikona.fixed
    : null

  return (
    <StyledIeguvums
      initial="initial"
      animate={animate ? "enter" : "initial"}
      variants={theme.motion.contentVariants}
      custom={{ delay: 0.2 + 0.1 * index }}
    >
      {ikona && (
        <Ikona variation={variation}>
          {ikonaFixed && <Img fixed={ikonaFixed} alt="" />}
        </Ikona>
      )}

      {virsraksts ? (
        virsraksts.html ? (
          <Virsraksts
            dangerouslySetInnerHTML={{
              __html: virsraksts.html,
            }}
          />
        ) : (
          <Virsraksts
            dangerouslySetInnerHTML={{
              __html: virsraksts
                .replace("\n", "<br />")
                .replace(/\*\*(.+)\*\*/, "<span>$1</span>"),
            }}
          />
        )
      ) : null}

      {teksts && <Teksts>{teksts}</Teksts>}
    </StyledIeguvums>
  )
}

const bounce = keyframes`
  0%, 40%, 80%, 100% { 
    transform: translateY(0);
  } 15% { 
    transform: translateY(-25%);
  }
`

const IconWrapper = styled.div`
  display: none;

  @media (min-width: ${rem(theme.screens.sm)}) {
    display: flex;
    justify-content: center;
    margin-top: ${rem(32)};

    > svg {
      display: block;
      animation: ${bounce} 1.5s infinite ease-in-out both;
    }
  }
`

function ArrowIcon() {
  return (
    <svg width="48" height="48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M24 13v22M35 24L24 35 13 24"
        stroke="#C8102E"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default function IkonuSekcija({
  data,
  high = false,
  withArrow = false,
  variation,
  className,
  style,
}) {
  const [ref, inView] = useInView({
    triggerOnce: true,
  })

  const { language } = React.useContext(LanguageContext)
  const { virsraksts, ieraksti } = translate(data, language)

  return (
    <Section
      ref={ref}
      high={high.toString()}
      className={className}
      style={style}
    >
      {virsraksts && (
        <SekcijasVirsraksts
          initial="initial"
          animate={inView ? "enter" : "initial"}
          variants={theme.motion.contentVariants}
        >
          {virsraksts}
        </SekcijasVirsraksts>
      )}

      <List>
        {ieraksti.map((ieraksts, i) => {
          return (
            <Ieguvums
              key={`ieguvums_${i + 1}`}
              animate={inView}
              index={i}
              data={translate(ieraksts, language)}
              variation={variation}
            />
          )
        })}
      </List>

      {withArrow && (
        <IconWrapper>
          <ArrowIcon />
        </IconWrapper>
      )}
    </Section>
  )
}
