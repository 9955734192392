import React from "react"
import Glide from "@glidejs/glide"
import "@glidejs/glide/dist/css/glide.core.min.css"
import { useInView } from "react-intersection-observer"
import styled from "styled-components"
import { motion } from "framer-motion"
import debounce from "lodash.debounce"
import { graphql, useStaticQuery } from "gatsby"
import theme from "../../core/theme"
import { rem } from "../../core/utils"
import { buttonReset } from "../../core/reset"
import { translate } from "../../core/helpers"
import { LanguageContext } from "../../core/context/language-context"
import TarifaKartina from "../TarifaKartina"

const Section = styled.section`
  background-color: ${theme.colors.variants.default.background.default};
  padding-bottom: ${rem(48)};

  @media (min-width: ${rem(theme.screens.sm)}) {
    background-color: ${theme.colors.variants.default.background.highlight};
  }
`

const Container = styled.div`
  max-width: ${rem(1280)};
  padding: ${rem(48)} ${rem(24)} ${rem(12)};

  @media (min-width: ${rem(theme.screens.sm)}) {
    margin-left: auto;
    margin-right: auto;
    padding: ${rem(56)} ${rem(56)} ${rem(12)};
  }
`

const Virsraksts = styled.h3`
  color: ${theme.colors.variants.default.text.heading};
  font-size: ${rem(32)};
  font-weight: 400;
  line-height: 1.2;
  margin-top: 0;
  margin-bottom: 0;
  text-align: center;

  @media (min-width: ${rem(theme.screens.sm)}) {
    margin-bottom: 0.75em;
  }
`

const GlideWrapper = styled.div`
  padding: ${rem(12)} ${rem(24)};
  overflow: hidden;

  .glide__track {
    overflow: visible;
  }

  .glide__slides {
    cursor: grab;
    margin-top: 0;
    margin-bottom: 0;
    overflow: visible;
  }

  .glide__slide {
    height: auto;
  }

  .glide__bullets {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: ${rem(24)} 0;
  }

  .glide__bullet,
  .glide__bullet--alt {
    ${buttonReset};

    background-color: #c4c4c4;
    border-radius: ${rem(2)};
    margin: 0 ${rem(4)};
    padding: 0;
    height: ${rem(4)};
    width: ${rem(12)};

    &:focus {
      outline: 0;
    }
  }

  .glide__bullet.glide__bullet--active {
    background-color: ${theme.colors.accent};
  }

  .glide__bullet--alt.active {
    background-color: ${theme.colors.accent};
  }

  @media (min-width: ${rem(theme.screens.sm)}) {
    padding: ${rem(12)} ${rem(56)};

    .glide__bullet,
    .glide__bullet--alt {
      width: ${rem(40)};
    }
  }

  @media (min-width: ${rem(1280)}) {
    padding-left: calc(
      (100vw - ${rem(1280)}) / 2 + ${rem(56 - 12)}
    ); /* 56 konteinera paddings; 12 glide.gap / 2 */
  }
`

export const tarifiSekcijaQuery = graphql`
  query TarifiSekcija {
    allStrapiTarifiSekcija {
      edges {
        node {
          virsraksts__lv
          # virsraksts__ru
          virsraksts__en
        }
      }
    }
  }
`

export default function TarifuPlaniSekcija({ tarifi }) {
  const wrapperRef = React.useRef()

  const [variant, setVariant] = React.useState("mobile")
  React.useEffect(() => {
    if (typeof window !== "undefined") {
      const minSmallScreen = window.matchMedia(
        `(min-width: ${rem(theme.screens.sm)})`
      )
      if (minSmallScreen && minSmallScreen.matches) {
        setVariant("desktop")
      }
    }
  }, [])

  const [glide, initGlide] = React.useState(null)

  // NOTE: Pievieno scroll interakciju labākam UX
  React.useEffect(() => {
    if (glide && variant === "desktop") {
      const target = wrapperRef.current
      const onScrollDebounce = debounce(onScroll, 100, { maxWait: 250 })

      function onScroll(event) {
        const delta = event.deltaX || event.wheelDeltaX
        if (!delta) {
          return false
        }
        event.preventDefault()
        glide.go(delta < 0 ? "<" : ">")
      }

      target.addEventListener("mousewheel", onScrollDebounce)

      return () => {
        target.removeEventListener("mousewheel", onScrollDebounce)
      }
    }
  }, [wrapperRef, glide, variant])

  React.useEffect(() => {
    if (glide) {
      // glide.on("mount.after", function () {
      //   const track = wrapperRef.current.querySelector(".glide__track")
      //   const { width: trackWidth } = track.getBoundingClientRect()

      //   const slide = wrapperRef.current.querySelector(".glide__slide")
      //   const { width: slideWidth } = slide.getBoundingClientRect()

      //   const perView = trackWidth / slideWidth
      // })

      if (variant === "desktop") {
        const start = wrapperRef.current.querySelector(
          `.glide__bullet--alt:nth-child(1)`
        )
        const end = wrapperRef.current.querySelector(
          `.glide__bullet--alt:nth-child(2)`
        )

        start.classList.add("active")

        glide.on("run.start", function () {
          start.classList.add("active")
          end.classList.remove("active")
        })

        glide.on("run.end", function () {
          start.classList.remove("active")
          end.classList.add("active")
        })

        // const slides = wrapperRef.current.querySelector(`.glide__slides`)
        // glide.on("swipe.start", function () {
        //   slides.style.cursor = "grabbing"
        // })
        // glide.on("swipe.end", function () {
        //   slides.style.cursor = "grab"
        // })
      }

      // glide.on("resize", function () {})

      glide.mount()
    } else {
      initGlide(
        new Glide(`.glide`, {
          bound: true,
          rewind: false,
          gap: 24,
          perView: 5,
          breakpoints: {
            340: {
              focusAt: "center",
              perView: 1.125,
              perTouch: 1,
            },
            500: {
              focusAt: "center",
              perView: 1.35,
              perTouch: 1,
            },
            [theme.screens.sm]: {
              perView: 2,
            },
            [theme.screens.md]: {
              perView: 2.5,
            },
            1280: {
              perView: 3.5,
            },
          },
        })
      )
    }

    return () => {
      if (glide) {
        try {
          glide.destroy()
        } catch (error) {}
      }
    }
  }, [wrapperRef, glide, variant])

  const [ref, inView] = useInView({
    triggerOnce: true,
  })

  const { language, tulkojums } = React.useContext(LanguageContext)
  const data = useStaticQuery(tarifiSekcijaQuery)
  const doc = data.allStrapiTarifiSekcija.edges.slice(0, 1).pop()
  if (!doc) return null

  const sekcija = translate(doc.node, language)

  return (
    <Section ref={ref}>
      <Container>
        <motion.div
          initial="initial"
          animate={inView ? "enter" : "initial"}
          variants={theme.motion.contentVariants}
        >
          <Virsraksts id="tarifu_plani_un_pakalpojumi">
            {sekcija.virsraksts}
          </Virsraksts>
        </motion.div>
      </Container>

      <GlideWrapper ref={wrapperRef}>
        <div className="glide">
          <div className="glide__track" data-glide-el="track">
            <ul className="glide__slides">
              {tarifi.map(({ node }, i) => {
                return (
                  <TarifaKartina
                    key={node.id}
                    index={i}
                    animate={inView}
                    className="glide__slide"
                    tulkojums={tulkojums}
                    data={node}
                  />
                )
              })}
            </ul>
          </div>

          <div className="glide__bullets" data-glide-el="controls[nav]">
            {variant === "mobile" ? (
              <>
                {tarifi.map((_, i) => (
                  <button
                    key={`glide__bullet--${i}`}
                    className="glide__bullet"
                    data-glide-dir={`=${i}`}
                  ></button>
                ))}
              </>
            ) : (
              <>
                <button className="glide__bullet--alt"></button>
                <button className="glide__bullet--alt"></button>
              </>
            )}
          </div>
        </div>
      </GlideWrapper>
    </Section>
  )
}
