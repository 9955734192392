export function getFormData(form) {
  const formData = new FormData(form)

  const piekritu = formData.has("piekritu-datu-apstradei") ? "ja" : "ne"
  formData.set("piekritu-datu-apstradei", piekritu)

  const esmuKlients = formData.has("esmu-lmt-klients") ? "ja" : "ne"
  formData.set("esmu-lmt-klients", esmuKlients)

  return formData
}

export function testForm(form, { debug = false } = {}) {
  const formData = getFormData(form)
  let formComlete = true

  if (formData.get("piekritu-datu-apstradei") === "ne") {
    formComlete = false
  }

  const kontakttalrunis = form.querySelector('input[name="kontakttalrunis"]')
  if (!kontakttalrunis.checkValidity()) {
    kontakttalrunis.focus()
    formComlete = false
    kontakttalrunis.classList.add("state--error")
  } else {
    kontakttalrunis.classList.remove("state--error")
  }

  const vards = form.querySelector('input[name="vards"]')
  if (!formData.get("vards") || formData.get("vards").length < 3) {
    vards.focus()
    formComlete = false
    vards.classList.add("state--error")
  } else {
    vards.classList.remove("state--error")
  }

  if (debug) {
    for (let pair of formData.entries()) {
      console.log(pair[0] + ", " + pair[1])
    }
  }

  return formComlete
}

export async function sendFormData(formData, onChange, opts = {}) {
  // NOTE: Pieejami tikai lauki: contact_phone un description

  const vards = formData.get("vards")
  const kontakttalrunis = formData.get("kontakttalrunis")
  const klients = formData.get("esmu-lmt-klients")

  const { tarifi, debug = false, simulate = false } = opts

  if (simulate) {
    onChange && onChange("success", { ok: true })
    return
  }

  let description = ""
  if (tarifi) {
    tarifi.forEach(tarifs => {
      const kam = formData.get(tarifs.id)
      description += kam ? `${tarifs.id}:${kam}|` : `${tarifs.id}|`
    })
  }

  if (vards) {
    description += `vards:${vards}|`
  }

  description += `klients:${klients}`

  const pieteikums = new FormData()
  pieteikums.set("contact_phone", kontakttalrunis)
  pieteikums.set("description", encodeURIComponent(description))

  // NOTE: FormData izmanto "multipart/form-data", konvertē par
  // "x-www-form-urlencoded" izmantojot URLSearchParams konstruktoru.
  // https://developer.mozilla.org/en-US/docs/Web/API/FormData
  const pieteikumsUrlEncoded = new URLSearchParams(pieteikums)

  if (debug) {
    for (let pair of formData.entries()) {
      console.log(pair[0] + ", " + pair[1])
    }

    console.log("description", description)

    for (let pair of pieteikums.entries()) {
      console.log(pair[0] + ", " + pair[1])
    }

    console.log("pieteikums", pieteikumsUrlEncoded.toString())
  }

  const headers = {
    Accept: "application/json",
    "Content-Type": "application/x-www-form-urlencoded",
  }

  try {
    const response = await fetch("/api/pieteikums.php", {
      headers,
      method: "POST",
      body: pieteikumsUrlEncoded,
    })

    if (debug) {
      console.log("response", response)
    }

    const data = await response.json()

    if (debug) {
      console.log("data", data)
    }

    onChange && onChange("success", { ...data, ok: data.success })
  } catch (error) {
    onChange && onChange("error", error)
  }
}
