import React from "react"
import styled, { css } from "styled-components"
import { motion } from "framer-motion"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"
// import RichText from "../RichText"
// import ExternalIcon from "../Footer/ExternalIcon"
import { listReset } from "../../core/reset"
import { rem } from "../../core/utils"
import theme from "../../core/theme"
import { LanguageContext } from "../../core/context/language-context"
import { translate } from "../../core/helpers"

// export const query = graphql`
//   query {
//     allDataJson(filter: { svarigi_zinat: { id: { eq: "svarigi_zinat" } } }) {
//       edges {
//         node {
//           svarigi_zinat {
//             virsraksts
//             ieraksti {
//               ikona {
//                 id
//               }
//               teksts
//               span
//             }
//             vairak {
//               teksts
//               saite {
//                 url
//               }
//             }
//             atruna {
//               teksts
//             }
//           }
//         }
//       }
//     }
//     ikona_info: file(relativePath: { eq: "ikonas/info_40@3x.png" }) {
//       childImageSharp {
//         fixed(width: 40, height: 40) {
//           ...GatsbyImageSharpFixed_noBase64
//         }
//       }
//     }
//     ikona_user_check: file(
//       relativePath: { eq: "ikonas/user-check_40@3x.png" }
//     ) {
//       childImageSharp {
//         fixed(width: 40, height: 40) {
//           ...GatsbyImageSharpFixed_noBase64
//         }
//       }
//     }
//     ikona_book: file(relativePath: { eq: "ikonas/book_40@3x.png" }) {
//       childImageSharp {
//         fixed(width: 40, height: 40) {
//           ...GatsbyImageSharpFixed_noBase64
//         }
//       }
//     }
//     ikona_docs: file(relativePath: { eq: "ikonas/docs_40@3x.png" }) {
//       childImageSharp {
//         fixed(width: 40, height: 40) {
//           ...GatsbyImageSharpFixed_noBase64
//         }
//       }
//     }
//     ikona_mobile: file(relativePath: { eq: "ikonas/mobile_40@3x.png" }) {
//       childImageSharp {
//         fixed(width: 40, height: 40) {
//           ...GatsbyImageSharpFixed_noBase64
//         }
//       }
//     }
//   }
// `

export const svarigiZinatQuery = graphql`
  query SvarigiZinatSekcija {
    strapiSvarigiZinatSekcija {
      virsraksts__lv
      # virsraksts__ru
      virsraksts__en
      Fakts {
        teksts__lv
        # teksts__ru
        teksts__en
        vairakas_kolonnas
        ikona {
          childImageSharp {
            fixed(width: 40, height: 40) {
              ...GatsbyImageSharpFixed_noBase64
            }
          }
        }
      }
      atruna__lv
      # atruna__ru
      atruna__en
    }
  }
`

const Section = styled.section`
  background-color: ${theme.colors.variants.default.background.highlight};
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: ${rem(1280)};
  padding: ${rem(96)} ${rem(24)} 0;

  @media (min-width: ${rem(theme.screens.sm)}) {
    background-color: ${theme.colors.variants.default.background.highlight};
    margin: ${rem(160)} auto ${rem(100)};
    max-width: ${rem(1280)};
    padding: ${rem(56)};
  }

  @media (min-width: ${rem(1280)}) {
    border-radius: ${rem(8)};
  }
`

const Toggle = styled.div`
  background-color: #ffffff;
  border-top: 1px solid #e8e8e8;
  color: #4e4e4e;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 ${rem(-24)};
  padding: ${rem(20)} ${rem(24)};
  user-select: none;
  width: calc(100% + ${rem(48)});

  ${({ open }) =>
    open &&
    css`
      > svg {
        transform: scaleY(-1);
      }
    `};
`

const ContentGroup = styled(motion.div)`
  overflow-y: hidden;
`

const IerakstiList = styled(motion.ul)`
  ${listReset};
  margin-bottom: ${rem(20)};
`

const Ieraksts = styled.li`
  display: flex;
  padding: ${rem(20)} 0;

  & + & {
    border-top: 1px solid #e8e8e8;
  }

  @media (min-width: ${rem(theme.screens.sm)}) {
    & + & {
      border-top: 0;
    }
  }
`

const IerakstsIcon = styled.div`
  flex: 0 0 auto;
  margin-right: ${rem(8)};
`

const IerakstsContent = styled.div`
  color: #4e4e4e;
  flex: 1 1 auto;

  a {
    color: ${theme.colors.accent};
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
`

const Ikona = styled.div`
  > div {
    width: ${rem(40)} !important;
    height: ${rem(40)} !important;
  }
`

// const VairakWrapper = styled.div`
//   display: flex;
//   justify-content: flex-end;
//   margin-bottom: ${rem(24)};

//   @media (min-width: ${rem(theme.screens.sm)}) {
//     margin-top: ${rem(24)};
//     margin-bottom: 0;
//   }
// `

// const ExternalLink = styled.a`
//   border: 1px solid currentColor;
//   border-radius: ${rem(5)};
//   color: ${theme.colors.accent};
//   display: inline-flex;
//   align-items: center;
//   margin: 0;
//   padding: ${rem(16)} ${rem(32)};
//   text-decoration: none;

//   > i {
//     display: inline-block;
//     margin-left: ${rem(8)};
//   }

//   &:hover {
//     background-color: rgba(200, 16, 46, 0.05);
//   }
// `

const panelVariants = {
  closed: {
    height: 0,
  },
  open: {
    height: "auto",
    transition: { type: "spring", damping: 25, stiffness: 200 },
  },
}

const contentVariants = {
  hidden: {
    opacity: 0,
    y: 20,
  },
  visible: {
    opacity: 1,
    y: 0,
    transition: { type: "spring", damping: 25, stiffness: 200 },
  },
}

function MobileSvarigiZinat({ data, language }) {
  const { virsraksts, Fakts, atruna } = translate(data, language)
  const [open, setOpen] = React.useState(false)
  return (
    <>
      <Toggle
        open={open}
        onClick={() => {
          setOpen(!open)
        }}
      >
        {virsraksts}
        <svg
          width="12"
          height="6"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M1 .5l5 5 5-5"
            stroke="#4E4E4E"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </Toggle>
      <ContentGroup
        initial="closed"
        animate={open ? "open" : "closed"}
        variants={panelVariants}
      >
        <IerakstiList
          initial="hidden"
          animate={open ? "visible" : "hidden"}
          variants={contentVariants}
        >
          {Fakts.map((ieraksts, i) => {
            const { ikona, teksts } = translate(ieraksts, language)
            return (
              <Ieraksts key={`ieraksts_mobile_${i + 1}`}>
                <IerakstsIcon>
                  <Ikona>
                    {ikona.childImageSharp ? (
                      ikona.childImageSharp.fixed && (
                        <Img fixed={ikona.childImageSharp.fixed} alt="" />
                      )
                    ) : ikona.fixed ? (
                      <Img fixed={ikona.fixed} alt="" />
                    ) : null}
                  </Ikona>
                </IerakstsIcon>
                <IerakstsContent>
                  {/* <RichText render={teksts} /> */}
                  <p
                    dangerouslySetInnerHTML={{
                      __html: teksts.replace(
                        /\*\*(.+)\*\*/,
                        "<strong>$1</strong>"
                      ),
                    }}
                  ></p>
                </IerakstsContent>
              </Ieraksts>
            )
          })}
        </IerakstiList>

        <AtrunaWrapper>
          <sup>*</sup>
          <IerakstsContent>{atruna}</IerakstsContent>
        </AtrunaWrapper>

        {/* <VairakWrapper>
          <ExternalLink
            href={data.vairak.saite.url}
            target="_blank"
            rel="noreferrer noopener"
          >
            {data.vairak.teksts} <ExternalIcon />
          </ExternalLink>
        </VairakWrapper> */}
      </ContentGroup>
    </>
  )
}

const Container = styled.div`
  margin-left: auto;
  margin-right: auto;
  /* max-width: ${rem(1080)}; */
`

const Virsraksts = styled.h4`
  color: #2d2d2d;
  font-size: ${rem(20)};
  margin-top: 0;
  margin-bottom: 1em;
`

const IerakstiDesktopList = styled.ul`
  ${listReset};
  display: flex;
  flex-wrap: wrap;
  margin: 0 ${rem(-24)} ${rem(-24)};

  > li {
    flex: 0 0 ${(1 / 3) * 100}%;
    /* margin: 0 ${rem(24)}; */
    margin-bottom: ${rem(24)};
    padding: 0 ${rem(24)};

    &[data-span] {
      flex: 0 0 ${(2 / 3) * 100}%;
    }
  }
`

const AtrunaWrapper = styled.div`
  display: flex;
  font-size: ${rem(14)};
  margin-bottom: ${rem(32)};

  > sup {
    margin-right: 0.35em;
  }

  @media (min-width: ${rem(theme.screens.sm)}) {
    margin-top: ${rem(48)};
    margin-bottom: 0;
  }
`

function DesktopSvarigiZinat({ data, language }) {
  const { virsraksts, Fakts, atruna } = translate(data, language)

  return (
    <Container>
      <Virsraksts>{virsraksts}</Virsraksts>
      <IerakstiDesktopList>
        {Fakts.map((ieraksts, i) => {
          const { ikona, teksts } = translate(ieraksts, language)
          return (
            <Ieraksts
              key={`ieraksts_desktop_${i + 1}`}
              data-span={ieraksts.vairakas_kolonnas}
            >
              <IerakstsIcon>
                <Ikona>
                  {ikona.childImageSharp ? (
                    ikona.childImageSharp.fixed && (
                      <Img fixed={ikona.childImageSharp.fixed} alt="" />
                    )
                  ) : ikona.fixed ? (
                    <Img fixed={ikona.fixed} alt="" />
                  ) : null}
                </Ikona>
              </IerakstsIcon>
              <IerakstsContent>
                {/* <RichText render={teksts} /> */}
                <p
                  dangerouslySetInnerHTML={{
                    __html: teksts.replace(
                      /\*\*(.+)\*\*/,
                      "<strong>$1</strong>"
                    ),
                  }}
                ></p>
              </IerakstsContent>
            </Ieraksts>
          )
        })}
      </IerakstiDesktopList>

      <AtrunaWrapper>
        <sup>*</sup>
        <IerakstsContent>{atruna}</IerakstsContent>
      </AtrunaWrapper>

      {/* <VairakWrapper>
        <ExternalLink
          href={data.vairak.saite.url}
          target="_blank"
          rel="noreferrer noopener"
        >
          {data.vairak.teksts} <ExternalIcon />
        </ExternalLink>
      </VairakWrapper> */}
    </Container>
  )
}

export default function SvarigiZinatSekcija() {
  // const queryData = useStaticQuery(query)

  // const data = { ...queryData.allDataJson.edges[0].node.svarigi_zinat }
  // data.ieraksti = data.ieraksti.map(ieraksts => {
  //   const id = ieraksts.ikona && ieraksts.ikona.id
  //   let ikona = queryData[`ikona_${id}`]
  //   if (ikona && ikona.childImageSharp) {
  //     ikona = ikona.childImageSharp
  //   }
  //   return {
  //     ...ieraksts,
  //     ikona: {
  //       ...ieraksts.ikona,
  //       fixed: ikona ? { ...ikona.fixed } : null,
  //     },
  //   }
  // })

  const { language } = React.useContext(LanguageContext)
  const data = useStaticQuery(svarigiZinatQuery)

  const [variant, setVariant] = React.useState("mobile")
  React.useEffect(() => {
    if (typeof window !== "undefined") {
      const minSmallScreen = window.matchMedia(
        `(min-width: ${rem(theme.screens.sm)})`
      )
      if (minSmallScreen && minSmallScreen.matches) {
        setVariant("desktop")
      }
    }
  }, [])

  const doc = data.strapiSvarigiZinatSekcija
  if (!doc) return null

  return (
    <Section>
      {variant === "mobile" ? (
        <MobileSvarigiZinat data={doc} language={language} />
      ) : (
        <DesktopSvarigiZinat data={doc} language={language} />
      )}
    </Section>
  )
}
