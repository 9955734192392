import React from "react"
import styled, { css } from "styled-components"
import { rem } from "../core/utils"

const StyledSelect = styled.div`
  background-color: #1d1d1d;
  border: 1px solid #323232;
  border-radius: ${rem(5)};
  color: #606060;
  display: flex;
  min-width: ${rem(90)};
  position: relative;
  user-select: none;
`

const LabelGroup = styled.div`
  flex: 1 1 auto;
  padding: ${rem(12)} ${rem(16)};
`

const ArrowGroup = styled.div`
  flex: 0 0 auto;
  border-left: 1px solid #323232;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: ${rem(12)} ${rem(8)};

  ${({ open }) =>
    open &&
    css`
      svg {
        transform: scaleY(-1);
      }
    `};
`

const OptGroup = styled.div`
  position: absolute;
  bottom: ${rem(-6)};
  left: 0;
  width: 100%;
  transform: translateY(100%);

  border: 1px solid #323232;
  border-radius: ${rem(5)};
  overflow: hidden;
`

const StyledOption = styled.div`
  background-color: #1d1d1d;
  color: #bababa;
  cursor: pointer;
  padding: ${rem(12)} ${rem(16)};

  &:hover {
    background-color: #323232;
  }

  & + & {
    border-top: 1px solid #323232;
  }

  ${({ selected }) =>
    selected &&
    css`
      color: #606060;
      cursor: auto;
      pointer-events: none;
    `};
`

export default function Dropdown({ options = [], onChange }) {
  const [selected, setSelected] = React.useState(() =>
    options.find(option => option.selected)
  )
  // React.useEffect(() => {
  //   onChange && onChange(selected.value)
  // }, [selected, onChange])

  const selectRef = React.useRef()
  const [open, setOpen] = React.useState(false)
  React.useEffect(() => {
    function onClick({ target }) {
      const isClickInside = selectRef.current.contains(target)
      if (!isClickInside) {
        setOpen(false)
      }
    }
    if (open) {
      document.body.addEventListener("click", onClick)
      return () => {
        document.body.removeEventListener("click", onClick)
      }
    }
  }, [open, selectRef])

  return (
    <StyledSelect
      ref={selectRef}
      onClick={() => {
        setOpen(!open)
      }}
    >
      <LabelGroup>
        {selected ? selected.text : null /* TODO: label */}
      </LabelGroup>
      <ArrowGroup open={open}>
        <svg
          width="12"
          height="6"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M1 .5l5 5 5-5"
            stroke="#BABABA"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </ArrowGroup>

      {open && (
        <OptGroup>
          {options.map(option => {
            const { value, text } = option
            return (
              <StyledOption
                key={value}
                value={value}
                onClick={() => {
                  setSelected(option)
                  onChange && onChange(value)
                }}
                selected={selected.value === value}
              >
                {text}
              </StyledOption>
            )
          })}
        </OptGroup>
      )}
    </StyledSelect>
  )
}
