import React from "react"
import styled, { css } from "styled-components"
import { buttonReset } from "../core/reset"
import { rem } from "../core/utils"
import theme from "../core/theme"

export const buttonStyle = css`
  ${buttonReset};

  background: ${theme.colors.accent};
  border: 1px solid ${theme.colors.accent};
  border-radius: ${rem(5)};
  color: ${theme.colors.white};
  cursor: pointer;
  margin-top: ${rem(32)};
  padding: ${rem(16)} ${rem(48)};

  outline-offset: 4px;
  &:focus {
    outline: 2px solid ${theme.colors.accent};
  }

  &:hover {
    background-color: #900c21;
    border-color: #900c21;
  }

  &:disabled {
    opacity: 0.5;
    pointer-events: none;
  }
`

const StyledButton = styled.button.attrs({ type: "button" })`
  ${buttonStyle};

  ${({ secondary }) =>
    secondary &&
    css`
      background-color: rgba(0, 0, 0, 0.1);
      backdrop-filter: blur(2px);
      border: 1px solid #C8102E;

      &:hover {
        background-color: #900c21;
        border-color: #900c21;
      }
    `};

  ${({ high }) =>
    high &&
    css`
      padding: ${rem(19)} ${rem(48)};
    `};
`

export default function Button({
  children,
  className,
  disabled,
  innerRef,
  onClick,
  style,
  title,
  high,
  secondary,
}) {
  return (
    <StyledButton
      className={className}
      disabled={disabled}
      onClick={onClick}
      ref={innerRef}
      style={style}
      title={title}
      high={high ? high.toString() : undefined}
      secondary={secondary}
    >
      {children}
    </StyledButton>
  )
}
