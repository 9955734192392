import React from "react"
import styled from "styled-components"
import theme from "../core/theme"
import { rem } from "../core/utils"

const StyledCena = styled.p`
  color: ${theme.colors.accent};
  display: flex;
  align-items: center;
  font-size: ${rem(44)};
  font-weight: 500;
  margin-top: 0;
  margin-bottom: 0;
  transform: translateZ(0);

  > span {
    display: flex;
    flex-direction: column;
    line-height: 1;
    margin-left: 0.05em;

    > sup {
      font-size: ${21 / 44}em;
      margin-top: -0.1em;
      margin-bottom: 0.1em;
    }

    > sub {
      font-size: ${12 / 44}em;
      font-weight: 500;
    }
  }
`

export default function Cena({ cena, tulkojums }) {
  const [euro, centi] = cena.toFixed(2).split(".")
  return (
    <StyledCena>
      {euro}
      <span>
        <sup>{centi}</sup>
        <sub>{`${tulkojums("eur_men")}*`}</sub>
      </span>
    </StyledCena>
  )
}
