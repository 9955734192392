import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import styled from "styled-components"
import { motion, useViewportScroll, useTransform } from "framer-motion"
// import Img from "gatsby-image"
import theme from "../../core/theme"
import { rem } from "../../core/utils"
import { translate } from "../../core/helpers"
import { LanguageContext } from "../../core/context/language-context"
import LanguageToggle from "../LanguageToggle"
import Button from "../Button"
import Logo from "../Logo"

const Section = styled.section`
  background-color: #cecece;
  height: 100vh;
  position: relative;
  z-index: 11; /* virs mobile status bar */
`

const Background = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
`

const Scrim = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  background: linear-gradient(
      180deg,
      rgba(14, 14, 14, 0) 48.96%,
      rgba(16, 16, 16, 0.52) 98.76%
    ),
    linear-gradient(
      45.88deg,
      rgba(22, 22, 22, 0.54) 36.09%,
      rgba(22, 22, 22, 0.12) 74.09%
    );

  @media (min-width: ${rem(theme.screens.sm)}) {
    background: linear-gradient(
      66.74deg,
      rgba(22, 22, 22, 0.85) -0.14%,
      rgba(22, 22, 22, 0) 93.68%
    );
  }
`

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;

  margin-left: auto;
  margin-right: auto;

  max-width: ${rem(1280)};
  padding: ${rem(44)} ${rem(24)};
  padding-bottom: ${rem(80)};

  @media (min-width: ${rem(theme.screens.sm)}) {
    padding: ${rem(56)};
    padding-bottom: ${rem(80)};
  }
`

const TopGroup = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const ContentGroup = styled.div`
  button {
    margin-top: ${rem(16)};
    width: 100%;
  }

  @media (min-width: ${rem(theme.screens.sm)}) {
    button {
      margin-top: ${rem(32)};
      width: auto;
    }
  }
`

const Overline = styled.p`
  color: ${theme.colors.white};
  font-size: ${rem(20)};
  margin-top: 0;
  margin-bottom: 0;
  opacity: 0.6;
`

const Headline = styled.h1`
  color: ${theme.colors.white};
  font-size: ${rem(44)};
  font-weight: 500;
  line-height: 1.1;
  margin-top: 0.5em;
  margin-bottom: 0.5em;
`

const ButtonGroup = styled(motion.div)`
  display: flex;
  flex-direction: column;

  button {
    width: 100%;
    max-width: ${rem(320)};
  }

  button + * {
    margin-top: ${rem(16)};

    button {
      margin-top: 0;
    }
  }

  @media (min-width: ${rem(theme.screens.sm)}) {
    flex-direction: row;

    button {
      margin-top: 0;
      width: auto;
    }

    button + * {
      margin-top: 0;
      margin-left: ${rem(32)};
    }
  }
`

// const HiddenSmall = styled.div`
//   display: none;
//   @media (min-width: ${rem(theme.screens.sm)}) {
//     display: block;
//   }
// `

// const VisibleSmallOnly = styled.div`
//   @media (min-width: ${rem(theme.screens.sm)}) {
//     display: none;
//   }
// `

const ImageFade = styled(motion.div)`
  width: 100%;
  height: 100%;
  overflow: hidden;

  div {
    width: 100%;
    height: 100%;
  }

  img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: 55% 20%;
  }
`

const fadeVariants = {
  hidden: {
    opacity: 0,
  },
  visible: {
    opacity: 1,
    transition: { delay: 0.1, duration: 0.75 },
  },
}

function BackgroundImage({ sekcija, onLoad }) {
  const [loaded, setLoaded] = React.useState(false)

  // NOTE: Vai parallax patiešām nepieciešams?
  const { scrollY } = useViewportScroll()
  const y = useTransform(scrollY, [0, 5], [0, 1], {
    clamp: false,
  })

  const onLoadCallback = React.useCallback(() => {
    onLoad && onLoad()
  }, [onLoad])

  const containerRef = React.useRef()
  React.useEffect(() => {
    const image = containerRef.current.querySelector("img")
    if (image.complete) {
      setLoaded(true)
      onLoadCallback && onLoadCallback()
    }
  }, [containerRef, onLoadCallback])

  return (
    <ImageFade
      initial="hidden"
      animate={loaded ? "visible" : "hidden"}
      variants={fadeVariants}
    >
      <motion.div style={{ y }} ref={containerRef}>
        <picture>
          <source
            media={`(max-width: ${rem(theme.screens.sm - 1)})`}
            // srcSet="/hero-mobile@2x.jpg"
            srcSet={sekcija.bilde_mobile.publicURL}
          />
          <source
            media={`(min-width: ${rem(theme.screens.sm)})`}
            // srcSet="/hero-desktop@2x.jpg"
            srcSet={sekcija.bilde_desktop.publicURL}
          />
          {/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */}
          <img
            // src="/hero-mobile@2x.jpg"
            src={sekcija.bilde_mobile.publicURL}
            alt=""
            onLoad={() => {
              setLoaded(true)
              onLoadCallback && onLoadCallback()
            }}
          />
          {/* eslint-enable jsx-a11y/no-noninteractive-element-interactions */}
        </picture>

        <Scrim />
      </motion.div>
    </ImageFade>
  )
}

const wordSpanVariants = {
  initial: {
    opacity: 0,
    x: 50,
  },
  enter: i => ({
    opacity: 1,
    x: 0,
    transition: {
      delay: 0.8 + 0.1 * i,
      type: "spring",
      damping: 50,
      stiffness: 150,
    },
  }),
}

function W({ children, custom }) {
  return (
    <motion.span
      initial="initial"
      animate="enter"
      variants={wordSpanVariants}
      custom={custom}
      style={{ display: "inline-block", whiteSpace: "pre" }}
    >
      {children}
    </motion.span>
  )
}

export const vakaSekcijaQuery = graphql`
  query VakaSekcija {
    allStrapiVakaSekcija {
      edges {
        node {
          virsraksts__lv
          # virsraksts__ru
          virsraksts__en

          papildteksts__lv
          # papildteksts__ru
          papildteksts__en

          cta__lv
          # cta__ru
          cta__en

          cta2__lv
          # cta2__ru
          cta2__en

          bilde_mobile {
            childImageSharp {
              fluid(maxWidth: 768) {
                ...GatsbyImageSharpFluid_withWebp_noBase64
              }
            }
            publicURL
          }

          bilde_desktop {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid_withWebp_noBase64
              }
            }
            publicURL
          }
        }
      }
    }
  }
`

export default function VakaSekcija({ komplektetajsRef, onPopupOpen }) {
  const { language } = React.useContext(LanguageContext)
  const [imageLoaded, setImageLoaded] = React.useState(false)

  const data = useStaticQuery(vakaSekcijaQuery)
  const doc = data.allStrapiVakaSekcija.edges.slice(0, 1).pop()
  if (!doc) return null

  const sekcija = translate(doc.node, language)

  return (
    <Section>
      <Background>
        <BackgroundImage
          sekcija={sekcija}
          onLoad={() => {
            setImageLoaded(true)
          }}
        />
      </Background>

      <Container>
        <TopGroup>
          <motion.div
            initial="initial"
            animate={imageLoaded ? "enter" : "initial"}
            variants={theme.motion.contentVariants}
          >
            <Logo />
          </motion.div>

          {/* krievu valoda deaktivizēta pēc klienta pieprasījuma */}
          <motion.div
            initial="initial"
            animate={imageLoaded ? "enter" : "initial"}
            variants={theme.motion.contentVariants}
            custom={{ delay: 0.2 }}
          >
            <LanguageToggle />
          </motion.div>
        </TopGroup>

        <ContentGroup>
          <motion.div
            initial="initial"
            animate="enter"
            variants={theme.motion.contentVariants}
            custom={{ delay: 0.75 }}
          >
            <Overline>{sekcija.papildteksts}</Overline>
          </motion.div>

          <Headline>
            {sekcija.virsraksts.split(" ").map((vards, i) => {
              return (
                <React.Fragment key={`${vards}_${i + 1}`}>
                  <W custom={i + 1}>{i > 0 ? ` ${vards}` : vards}</W>
                  {/* <span>{` `}</span> */}
                </React.Fragment>
              )
            })}
          </Headline>

          <ButtonGroup
            initial="initial"
            animate="enter"
            variants={theme.motion.contentVariants}
            custom={{ delay: 1.6 }}
          >
            <Button
              high
              onClick={() => {
                if (komplektetajsRef && komplektetajsRef.current) {
                  komplektetajsRef.current.scrollIntoView({
                    behavior: "smooth",
                  })
                }
              }}
            >
              {sekcija.cta}
            </Button>

            <motion.div
              initial="initial"
              animate="enter"
              variants={theme.motion.contentVariants}
              custom={{ delay: 1.8 }}
            >
              <Button secondary high onClick={onPopupOpen}>
                {sekcija.cta2}
              </Button>
            </motion.div>
          </ButtonGroup>
        </ContentGroup>
      </Container>
    </Section>
  )
}
