import React from "react"
import styled from "styled-components"
import { motion } from "framer-motion"
import Confetti from "react-dom-confetti"
import { rem } from "../core/utils"
import theme from "../core/theme"
import Button from "./Button"
import { LanguageContext } from "../core/context/language-context"

const MessageWrapper = styled(motion.div)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;

  p {
    color: #e8e8e8;
    font-size: ${rem(20)};
    margin-top: 0;
    margin-bottom: 1em;
    text-align: center;
  }

  p + button {
    margin-top: 0;
  }

  img {
    display: block;
    width: ${rem(40)};
    height: ${rem(40)};
  }
`

export default function FormSuccessMessage({ onChange }) {
  const { tulkojums } = React.useContext(LanguageContext)
  const [confetti, setConfetti] = React.useState(false)

  React.useEffect(() => {
    setConfetti(true)
  }, [])

  return (
    <MessageWrapper initial={{ opacity: 0 }} animate={{ opacity: 1 }}>
      <p>{tulkojums("paldies_pieteikums_sanemts")}</p>
      <motion.img
        initial={{ opacity: 0, scale: 0.2 }}
        animate={{ opacity: 1, scale: 1, transition: { delay: 0.25 } }}
        src="/emoji/sirds_40@3.png"
        alt=""
      />
      <motion.div
        initial="initial"
        animate="enter"
        variants={theme.motion.contentVariants}
        custom={{ delay: 0.5 }}
      >
        <Button
          onClick={() => {
            onChange && onChange()
          }}
        >
          {tulkojums("aizvert")}
        </Button>
      </motion.div>

      <Confetti
        active={confetti}
        config={{
          spread: 120,
          colors: ["#C8102E", "#9D2235", "#FA4616", "#FF6A13", "#C8C9C7"],
        }}
      />
    </MessageWrapper>
  )
}
