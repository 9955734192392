import React from "react"
import styled, { ThemeProvider } from "styled-components"
import { motion } from "framer-motion"
import { rem } from "../core/utils"
import theme from "../core/theme"
import ApplicationForm from "./ApplicationForm"
import FormSuccessMessage from "./FormSuccessMessage"
import FormErrorMessage from "./FormErrorMessage"
import Button from "./Button"
import { getFormData, testForm, sendFormData } from "../core/form"
import { LanguageContext } from "../core/context/language-context"

const Wrapper = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 50;

  display: flex;
  overflow-y: hidden;

  &::before {
    background-color: rgba(0, 0, 0, 0.5);
    content: "";
    display: block;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -1;
  }
`

const StyledPopup = styled(motion.div)`
  background: #1d1d1d;
  border-radius: ${rem(7)};
  margin: auto;
  padding: ${rem(32)} ${rem(16)};
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 100%;
  max-width: ${rem(700)};

  > form {
    max-width: ${rem(320)};
    margin-left: auto;
    margin-right: auto;

    > div:nth-child(1) {
      padding-top: 0;
      position: static;

      > button {
        transform: none;
        top: -14px;
        right: -14px;

        &:focus {
          box-shadow: 0 0 0 4px rgba(255, 125, 48, 0.32);
          outline: 0;
        }
      }
    }

    > div:nth-child(2) {
      display: flex;
      flex-direction: column;

      > input {
        flex: 1;
        margin: 0;
      }

      > input + input {
        margin-top: ${rem(12)};
      }
    }
  }

  @media (min-height: ${rem(569)}) {
    height: auto;
  }

  @media (min-width: ${rem(theme.screens.custom.mobile)}) {
    padding: ${rem(40)};

    > form {
      > div:nth-child(1) {
        > button {
          top: 4px;
          right: 4px;
        }
      }
    }
  }
`

const ActionGroup = styled.div`
  border-top: 1px solid #3d3d3d;
  margin: ${rem(16)} ${rem(-32)} ${rem(-32)};
  padding: ${rem(30)};
  padding-top: 0;
  text-align: center;

  > button {
    margin-top: ${rem(21)};
    max-width: ${rem(320)};
    width: 100%;
  }

  @media (min-width: ${rem(theme.screens.custom.mobile)}) {
    margin: ${rem(32)} ${rem(-40)} ${rem(-40)};

    > button {
      margin-top: ${rem(32)};
    }
  }
`

const MessageGroup = styled.div`
  flex: 1;

  @media (min-width: ${rem(theme.screens.sm)}) {
    padding: ${rem(32)} ${rem(40)};
  }
`

const popupVariants = {
  initial: {
    opacity: 0,
    y: 50,
  },
  enter: {
    opacity: 1,
    y: 0,
    transition: { type: "spring", damping: 50, stiffness: 250 },
  },
}

export default function ApplicationFormPopup({ onClose }) {
  const { tulkojums } = React.useContext(LanguageContext)

  React.useEffect(() => {
    document.body.classList.add("scroll--disabled")
    return () => {
      document.body.classList.remove("scroll--disabled")
    }
  }, [])

  const wrapperRef = React.useRef()
  const formRef = React.useRef()

  const [status, setStatus] = React.useState("")
  const showContent = status !== "success" && status !== "error"

  React.useEffect(() => {
    if (status === "processing") {
      document.body.classList.add("fade-dark")
    } else {
      document.body.classList.remove("fade-dark")
    }
    return () => {
      document.body.classList.remove("fade-dark")
    }
  }, [status])

  return (
    <Wrapper ref={wrapperRef}>
      <StyledPopup
        initial="initial"
        animate="enter"
        variants={popupVariants}
        onAnimationComplete={() => {
          wrapperRef.current.style.overflowY = "auto"
        }}
      >
        {showContent ? (
          <>
            <ThemeProvider theme={theme.colors.variants.default}>
              <ApplicationForm
                formRef={formRef}
                inPopup={true}
                onClose={onClose}
                disabled={status === "processing"}
              />
            </ThemeProvider>

            <ActionGroup>
              <Button
                disabled={status === "processing"}
                onClick={() => {
                  const formComplete = testForm(formRef.current)
                  if (formComplete) {
                    setStatus("processing")

                    const formData = getFormData(formRef.current)

                    sendFormData(
                      formData,
                      (status, info) => {
                        if (status === "success" && info.ok) {
                          setStatus("success")
                        } else {
                          setStatus("error")
                          console.log(info)
                        }
                      },
                      {
                        /* debug: true */
                      }
                    )
                  }
                }}
              >
                {tulkojums("nosutit")}
              </Button>
            </ActionGroup>
          </>
        ) : (
          <MessageGroup>
            {status === "success" && (
              <FormSuccessMessage
                onChange={() => {
                  onClose && onClose()
                }}
              />
            )}

            {status === "error" && (
              <FormErrorMessage
                onChange={() => {
                  setStatus("")
                }}
              />
            )}
          </MessageGroup>
        )}
      </StyledPopup>
    </Wrapper>
  )
}
