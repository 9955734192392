import React from "react"
import styled, { css } from "styled-components"
import { motion, useAnimation } from "framer-motion"
import theme from "../core/theme"
import { rem } from "../core/utils"
import { listReset } from "../core/reset"
import ProductEmpty from "./ProductEmpty"
import ProductCard from "./ProductCard"
import FormSuccessMessage from "./FormSuccessMessage"
import FormErrorMessage from "./FormErrorMessage"
import Button from "./Button"
import ApplicationForm from "./ApplicationForm"
import { getFormData, testForm, sendFormData } from "../core/form"

const StyledDropZone = styled(motion.div)`
  color: ${theme.colors.white};
  display: flex;
  margin-top: ${rem(32)};
  min-height: ${rem(560)};
  position: relative;

  > div:first-child {
    filter: brightness(1);
    transition: filter 200ms ease-out;
  }

  ${({ isActive }) =>
    isActive &&
    css`
      @media (min-width: ${rem(theme.screens.sm)}) {
        > div:first-child {
          border-color: ${theme.colors.accent};
          filter: brightness(0.8);
        }
      }
    `};
`

const BackgroundGroup = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: hidden;

  background-color: #1d1d1d;
  border-radius: ${rem(7)};
  box-shadow: inset 0px 0px 20px #000;
  display: flex;

  > svg {
    display: none;
  }

  @media (min-width: ${rem(theme.screens.sm)}) {
    border: 2px solid #606060;

    > svg {
      display: block;
      position: absolute;
      top: 0;
      left: 0;
    }
  }
`

const DropIconGroup = styled(motion.div)`
  color: #656565;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: auto;
  position: relative;

  span {
    display: inline-block;
    margin-bottom: ${rem(24)};
    text-align: center;
    width: 100%;
  }
`

function DropBg() {
  return (
    <svg
      width="689"
      height="361"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M688.687 0H0v207.742L371.059 347.23l2.119.432c66.294 22.303 132.389 14.789 187.806-19.162 54.168-33.101 91.795-90.521 101.384-156.952C668.372 131.003 678.374 66.31 688.687 0z"
        fill="#2D2D2D"
        fillOpacity=".6"
      />
    </svg>
  )
}

function DropIcon() {
  return (
    <svg
      width="126"
      height="126"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="63" cy="63" r="62.5" stroke="currentColor" />
      <path
        d="M63 46.5v33M79.5 63L63 79.5 46.5 63"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

const ContentGroup = styled.div`
  flex: 1;
  overflow: hidden;
  padding: ${rem(24)} ${rem(8)};

  @media (min-width: ${rem(theme.screens.custom.mobile)}) {
    padding: ${rem(24)};
  }

  @media (min-width: ${rem(theme.screens.sm)}) {
    padding: ${rem(32)} ${rem(40)};
  }
`

const PaddingBox = styled.div`
  height: 100%;
  position: relative;
`

const MessageGroup = styled.div`
  flex: 1;
  overflow: hidden;
  padding: ${rem(32)} ${rem(40)};
  z-index: 1;
`

const Title = styled.h3`
  color: #e8e8e8;
  font-size: ${rem(20)};
  font-weight: 400;
  margin-top: 0;
  margin-bottom: 0;
  text-align: center;

  @media (min-width: ${rem(theme.screens.sm)}) {
    color: ${theme.colors.white};
    font-size: ${rem(32)};
    text-align: left;
  }
`

const ProductList = styled.ul`
  ${listReset};

  display: flex;
  flex-wrap: wrap;
  margin: ${rem(-7)};
  margin-top: ${rem(18)};
  margin-bottom: ${rem(216)};
`

const ActionGroup = styled.div`
  position: absolute;
  right: 0;
  bottom: 0;
  width: 100%;

  > button {
    display: block;
    width: 100%;
    max-width: ${rem(280)};
    margin-left: auto;
    margin-right: auto;
  }

  @media (min-width: ${rem(theme.screens.sm)}) {
    width: auto;

    > button {
      margin-right: 0;
      width: auto;
    }
  }
`

const PriceGroup = styled.div`
  color: #bababa;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  transform: translateZ(0);

  margin: 0 ${rem(-8)};
  padding: 0 ${rem(24)} ${rem(24)};
  border-bottom: 1px solid #3d3d3d;

  @media (min-width: ${rem(theme.screens.custom.mobile)}) {
    margin: 0 ${rem(-24)};
    padding: 0 ${rem(32)} ${rem(24)};
  }

  @media (min-width: ${rem(theme.screens.sm)}) {
    margin: 0;
    padding: 0;
    border-bottom: 0;
  }
`

const Price = styled(motion.p)`
  color: ${theme.colors.accent};
  display: flex;
  align-items: center;
  font-size: ${rem(44)};
  font-weight: 500;
  margin-top: 0;
  margin-bottom: 0;
  transform: translateZ(0);

  > span {
    display: flex;
    flex-direction: column;
    line-height: 1;
    margin-left: 0.05em;

    > sup {
      font-size: ${rem(21)};
    }

    > sub {
      font-size: ${rem(12)};
      font-weight: 500;
    }
  }
`

// NOTE: Piedāvājums darbosies, ja kopā pieslēgumu
// ir vismaz 2 un vismaz 1 "kvalificētais pieslēgums".

function sum(items, prop) {
  return items.reduce((prev, curr) => prev + curr[prop], 0)
}

function CalculatedPrice({ tarifi, tulkojums, piedavajums }) {
  const controls = useAnimation()

  const kopa = sum(tarifi, piedavajums ? "piedavajuma_cena" : "pilna_cena")
  const [euro, centi] = kopa.toFixed(2).split(".")

  const pilnaCena = sum(tarifi, "pilna_cena")
  const ietaupijums = pilnaCena - kopa

  React.useEffect(() => {
    controls.start({
      scale: 1,
      transition: {
        type: "spring",
        velocity: 50,
        stiffness: 500,
        damping: 80,
      },
    })
  }, [controls, kopa])

  return (
    <PriceGroup>
      {`${tulkojums("cena_piedavajuma_ietvaros")}:`}
      <Price animate={controls}>
        {euro}
        <span>
          <sup>{centi}</sup>
          <sub>{`${tulkojums("eur_men")}*`}</sub>
        </span>
      </Price>
      {`${tulkojums("ietaupijums")}: ${ietaupijums
        .toFixed(2)
        .replace(".", ",")} ${tulkojums("eur_men")}`}
    </PriceGroup>
  )
}

const HiddenSmall = styled.div`
  display: none;
  @media (min-width: ${rem(theme.screens.sm)}) {
    display: block;
  }
`

const VisibleSmallOnly = styled.div`
  @media (min-width: ${rem(theme.screens.sm)}) {
    display: none;
  }
`

const variants = {
  hidden: {
    opacity: 0,
  },
  visible: {
    opacity: 1,
  },
}

export default function DropZone({
  tarifi,
  hidden,
  innerRef,
  isActive,
  tulkojums,
  onProductRemove,
  onComplete,
  variant,
}) {
  const [status, setStatus] = React.useState("")

  React.useEffect(() => {
    if (status === "processing") {
      document.body.classList.add("fade-dark")
    } else {
      document.body.classList.remove("fade-dark")
    }
    return () => {
      document.body.classList.remove("fade-dark")
    }
  }, [status])

  const empty = Array.from(
    { length: 5 - tarifi.length },
    (_, i) => tarifi.length + i + 1
  )

  const formRef = React.useRef()
  const kamPieslegsiRef = React.useRef(new Map())

  async function onApply() {
    if (status === "apply") {
      const formComlete = testForm(formRef.current)

      if (formComlete) {
        const formData = getFormData(formRef.current)
        tarifi.forEach(tarifs => {
          formData.append(
            tarifs.id,
            kamPieslegsiRef.current.get(tarifs.id) || "-"
          )
        })

        setStatus("processing")

        // Google Tag Manager event
        window.dataLayer &&
          window.dataLayer.push({ event: "familyApplicationCompleted" })

        sendFormData(
          formData,
          (status, info) => {
            if (status === "success" && info.ok) {
              setStatus("success")
            } else {
              setStatus("error")
              console.log(info)
            }
          },
          { tarifi /* debug: true */ }
        )
      }
    } else {
      // Google Tag Manager event
      window.dataLayer && window.dataLayer.push({ event: "familyApplyClick" })

      setStatus("apply")
    }
  }

  const showContent = status !== "success" && status !== "error"

  const raditPiedavajumaCenu =
    tarifi.filter(tarifs => tarifs.ieklaut_piedavajuma_aprekina).length > 1 &&
    tarifi.some(tarifs => tarifs.kvalificets)

  return (
    <motion.div
      ref={innerRef}
      animate={hidden ? "hidden" : "visible"}
      variants={variants}
    >
      {!hidden && (
        <StyledDropZone isActive={isActive}>
          <BackgroundGroup>
            <DropBg />
            {tarifi.length === 0 && (
              <DropIconGroup
                animate={
                  isActive
                    ? { opacity: 0, transition: { duration: 0.2 } }
                    : { opacity: 1 }
                }
              >
                <HiddenSmall>
                  <span>{tulkojums("ievelc_seit")}</span>
                  <DropIcon />
                </HiddenSmall>

                <VisibleSmallOnly>
                  <span>
                    {tulkojums("pievieno_savam_komplektam")}
                    <br />
                    {tulkojums("kadu_no_pakalpojumiem")}
                  </span>
                </VisibleSmallOnly>
              </DropIconGroup>
            )}
          </BackgroundGroup>

          {showContent && (
            <ContentGroup>
              <PaddingBox>
                <HiddenSmall>
                  <Title>{tulkojums("komplekta_kalkulators")}</Title>
                </HiddenSmall>

                <VisibleSmallOnly>
                  <Title>
                    {`${tulkojums("komplekts")} `}({tarifi.length}/5)
                  </Title>
                </VisibleSmallOnly>

                {tarifi.length > 0 && (
                  <>
                    <ProductList>
                      {tarifi.map((tarifs, i) => {
                        return (
                          <ProductCard
                            /* uid tiek iekļauts Komplektetajs > onProductDrop */
                            key={tarifs.uid}
                            {...tarifs}
                            raditPiedavajumaCenu={raditPiedavajumaCenu}
                            onProductRemove={onProductRemove}
                            kamPieslegsiRef={kamPieslegsiRef}
                            tulkojums={tulkojums}
                          />
                        )
                      })}

                      {variant === "desktop" &&
                        empty.map(index => (
                          <ProductEmpty key={`empty_${index}`} index={index} />
                        ))}
                    </ProductList>

                    <ActionGroup>
                      <CalculatedPrice
                        tarifi={tarifi}
                        tulkojums={tulkojums}
                        piedavajums={raditPiedavajumaCenu}
                      />
                      <Button
                        disabled={status === "processing"}
                        onClick={onApply}
                      >
                        {status === "apply"
                          ? tulkojums("nosutit")
                          : tulkojums("pieteikties_piedavajumam")}
                      </Button>
                    </ActionGroup>

                    {status === "apply" && (
                      <ApplicationForm
                        formRef={formRef}
                        disabled={status === "processing"}
                        inline={variant === "desktop"}
                        onClose={() => {
                          setStatus("")
                        }}
                      />
                    )}
                  </>
                )}
              </PaddingBox>
            </ContentGroup>
          )}

          {!showContent && (
            <MessageGroup>
              {status === "success" && (
                <FormSuccessMessage
                  onChange={() => {
                    setStatus("")
                    onComplete && onComplete()
                  }}
                />
              )}

              {status === "error" && (
                <FormErrorMessage
                  onChange={() => {
                    setStatus("")
                  }}
                />
              )}
            </MessageGroup>
          )}
        </StyledDropZone>
      )}
    </motion.div>
  )
}
