import React from "react"
import styled from "styled-components/macro"

import unified from "unified"
import parse from "remark-parse"
import rehype from "remark-rehype"
import sanitize from "rehype-sanitize"
// import stringify from "rehype-stringify";
import rehype2react from "rehype-react"

// TODO: 'tel:' un, iespējams, 'mailto:' saitēm nav href atribūta

const processor = unified()
  .use(parse)
  .use(rehype)
  .use(sanitize)
  .use(rehype2react, {
    createElement: React.createElement,
    Fragment: React.Fragment,
  })

export const processRichText = text => processor.processSync(text).result

const Wrapper = styled.div`
  > * {
    &:first-child {
      margin-top: 0;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }
`

export default function RichText({ render, as, className, style }) {
  return (
    <Wrapper as={as} className={className} style={style}>
      {processRichText(render)}
    </Wrapper>
  )
}
