import React from "react"
import styled from "styled-components"
import { motion } from "framer-motion"
import Img from "gatsby-image"
import theme from "../core/theme"
import { rem } from "../core/utils"
import { buttonReset } from "../core/reset"
import Input from "./Input"

const StyledProductCard = styled(motion.li)`
  background-color: #323232;
  border-radius: ${rem(7)};
  flex: 0 1 auto;
  display: flex;
  flex-direction: column;
  margin: ${rem(7)};
  padding: ${rem(16)} ${rem(12)};
  width: 100%;

  @media (min-width: ${rem(theme.screens.sm)}) {
    background-color: transparent;
    border-radius: 0;
    padding: 0;
    width: ${rem(204)}; /* auto */
  }
`

const ContentGroup = styled.div`
  display: flex;
  align-items: center;

  > span[role="img"] {
    display: inline-block;
    font-size: ${rem(26)};
    line-height: 1;
    margin-bottom: ${rem(12)};
  }

  > img {
    display: block;
    width: ${rem(40)};
    height: ${rem(40)};
    margin-right: ${rem(8)};
  }

  @media (min-width: ${rem(theme.screens.sm)}) {
    background-color: #323232;
    border: 1px solid #323232;
    border-radius: ${rem(7)} ${rem(7)} 0 0;
    box-shadow: 0px 2px 6px rgba(45, 45, 45, 0.15);
    display: block;
    padding: ${rem(16)} ${rem(12)};
    text-align: center;
    width: 100%;

    > img {
      margin: auto;
    }
  }
`

const Name = styled.h4`
  color: #e8e8e8;
  font-weight: 400;
  margin-top: 0;
  margin-bottom: 0;
  margin-left: 0.5em;

  @media (min-width: ${rem(theme.screens.sm)}) {
    margin-left: 0;
  }
`

const PriceGroup = styled.div`
  color: #bababa;
  margin-right: ${rem(8)};
  padding-left: ${rem(8)};
  white-space: nowrap;

  > del {
    display: block;
  }

  > ins {
    color: #c8102e;
    display: block;
    margin-top: 0.2em;
    text-decoration: none;
  }

  @media (min-width: ${rem(theme.screens.sm)}) {
    background-color: #1d1d1d;
    border: 1px solid #323232;
    box-shadow: 0px 2px 6px rgba(45, 45, 45, 0.15);
    border-radius: 0 0 ${rem(7)} ${rem(7)};
    margin-right: 0;
    padding: ${rem(16)};
    text-align: center;
    width: 100%;
  }
`

const RenameGroup = styled.div`
  @media (min-width: ${rem(theme.screens.sm)}) {
    margin-top: ${rem(8)};
  }
`

function TrashIcon() {
  return (
    <svg width="40" height="40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12 13h16M16.571 13.333v-1.416c0-.509.181-.996.502-1.356.322-.36.758-.561 1.213-.561h3.428c.455 0 .891.202 1.213.561.321.36.502.847.502 1.356v1.416m2.571 0v14.75c0 .509-.18.996-.502 1.356-.322.36-.758.561-1.212.561h-8.572c-.454 0-.89-.202-1.212-.561A2.037 2.037 0 0114 28.083v-14.75h12zM18 19v6M22 19v6"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

const RemoveButton = styled.button.attrs({ type: "button" })`
  ${buttonReset};

  cursor: pointer;
  display: flex;
  justify-content: center;

  color: #878787;
  padding: 0.5em;

  &:hover {
    color: ${theme.colors.accent};
  }

  &:focus {
    outline: 1px solid ${theme.colors.accent};
  }
`

const ContentMobileRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media (min-width: ${rem(theme.screens.sm)}) {
    flex-direction: column;
    justify-content: flex-start;
  }
`

const ActionMobileRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: ${rem(8)};

  > button {
    transform: translateX(${rem(8)});
  }

  @media (min-width: ${rem(theme.screens.sm)}) {
    flex-direction: column;
    justify-content: flex-start;
    margin-top: 0;

    > button {
      margin-left: auto;
      margin-right: auto;
      transform: none;
    }
  }
`

const cardVariants = {
  initial: { opacity: 0, scale: 0.8 },
  enter: { opacity: 1, scale: 1 },
  exit: { opacity: 0 },
}

export default function ProductCard({
  id,
  uid,
  nosaukums,
  pilna_cena,
  piedavajuma_cena,
  raditPiedavajumaCenu,
  emoji,
  onProductRemove,
  kamPieslegsiRef,
  tulkojums,
}) {
  const formatCena = cena =>
    `${cena.toFixed(2).replace(".", ",")} ${tulkojums("eur_men")}`
  return (
    <StyledProductCard
      initial="initial"
      animate="enter"
      exit="exit"
      variants={cardVariants}
      positionTransition={{ damping: 40, stiffness: 160 }}
    >
      <ContentMobileRow>
        <ContentGroup>
          {emoji.childImageSharp ? (
            <Img fixed={emoji.childImageSharp.fixed} alt="" />
          ) : (
            <img src={emoji.img} alt="" />
          )}
          <Name>{nosaukums}</Name>
        </ContentGroup>

        <PriceGroup>
          {raditPiedavajumaCenu && pilna_cena !== piedavajuma_cena ? (
            <>
              <del>{formatCena(pilna_cena)}</del>
              <ins>{formatCena(piedavajuma_cena)}</ins>
            </>
          ) : raditPiedavajumaCenu ? (
            /* abas cenas vienādas, rādām tikai vienu - bez svītrojuma */
            <span style={{ display: "inline-block", padding: "0.7em 0" }}>
              {formatCena(pilna_cena)}
            </span>
          ) : (
            formatCena(pilna_cena)
          )}
        </PriceGroup>
      </ContentMobileRow>

      <ActionMobileRow>
        <RenameGroup>
          <Input
            placeholderText={tulkojums("kam_pieslegsi")}
            onChange={value => {
              kamPieslegsiRef.current.set(id, value)
            }}
          />
        </RenameGroup>

        <RemoveButton
          title={tulkojums("nonemt")}
          onClick={() => {
            onProductRemove && onProductRemove(uid)
          }}
        >
          <TrashIcon />
        </RemoveButton>
      </ActionMobileRow>
    </StyledProductCard>
  )
}
