import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import SEO from "../components/SEO"
import Layout from "../components/Layout"

import VakaSekcija from "../components/sections/VakaSekcija"
import IeguvumiSekcija from "../components/sections/IeguvumiSekcija"
import Komplektetajs from "../components/sections/Komplektetajs"
// import PiedzivojumsSekcija from "../components/sections/PiedzivojumsSekcija"
import TarifuPlaniSekcija from "../components/sections/TarifuPlaniSekcija"
import IzveliesLmtSekcija from "../components/sections/IzveliesLmtSekcija"
import PiesakiesSekcija from "../components/sections/PiesakiesSekcija"
import SvarigiZinatSekcija from "../components/sections/SvarigiZinatSekcija"
import ApplicationFormPopup from "../components/ApplicationFormPopup"

import { LanguageContext } from "../core/context/language-context"
import { translate } from "../core/helpers"

export const tarifiQuery = graphql`
  query Tarifi {
    allStrapiTarifs(sort: { fields: seciba }) {
      edges {
        node {
          id: uid
          nosaukums__lv
          # nosaukums__ru
          nosaukums__en
          apraksts__lv
          # apraksts__ru
          apraksts__en
          apraksts_isi__lv
          # apraksts_isi__ru
          apraksts_isi__en
          kvalificets
          neierobezots
          pilna_cena
          piedavajuma_cena
          ieklaut_kalkulatora
          ieklaut_piedavajuma_aprekina
          ieklaut_tarifu_sekcija
          emoji {
            childImageSharp {
              fixed(width: 40, height: 40) {
                ...GatsbyImageSharpFixed_noBase64
              }
            }
            publicURL
          }
          bilde {
            childImageSharp {
              fluid(maxWidth: 350) {
                ...GatsbyImageSharpFluid_withWebp_noBase64
              }
            }
            publicURL
          }
          vairak_saite__lv
          # vairak_saite__ru
          vairak_saite__en
        }
      }
    }
  }
`

export default function IndexPage({ pageContext }) {
  const data = useStaticQuery(tarifiQuery)
  const { language } = React.useContext(LanguageContext)
  const contextLang = pageContext && pageContext.lang && pageContext.lang.id
  const tarifi = data.allStrapiTarifs.edges.map(({ node }) => ({
    node: translate(node, contextLang || language),
  }))

  const komplektetajsRef = React.useRef()

  const [popupOpen, setPopupOpen] = React.useState(false)

  return (
    <Layout pageContext={pageContext}>
      <SEO />

      <VakaSekcija
        komplektetajsRef={komplektetajsRef}
        onPopupOpen={() => {
          setPopupOpen(true)
        }}
      />
      <IeguvumiSekcija />
      <Komplektetajs
        innerRef={komplektetajsRef}
        tarifi={tarifi.filter(({ node }) => node.ieklaut_kalkulatora)}
      />
      <TarifuPlaniSekcija
        tarifi={tarifi.filter(({ node }) => node.ieklaut_tarifu_sekcija)}
      />
      {/* <PiedzivojumsSekcija /> */}
      <IzveliesLmtSekcija />
      <PiesakiesSekcija
        onPopupOpen={() => {
          setPopupOpen(true)
        }}
      />
      <SvarigiZinatSekcija />

      {popupOpen && (
        <ApplicationFormPopup
          onClose={() => {
            setPopupOpen(false)
          }}
        />
      )}
    </Layout>
  )
}
