import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import IkonuSekcija from "./IkonuSekcija"

// export const ieguvumiQuery = graphql`
//   query {
//     allDataJson(filter: { izvelies_lmt: { id: { eq: "izvelies_lmt" } } }) {
//       edges {
//         node {
//           izvelies_lmt {
//             virsraksts
//             ieraksti {
//               ikona {
//                 id
//               }
//               virsraksts {
//                 html
//               }
//               teksts
//             }
//           }
//         }
//       }
//     }
//     ikona_star: file(relativePath: { eq: "ikonas/star_56@3x.png" }) {
//       childImageSharp {
//         fixed(width: 56, height: 56) {
//           ...GatsbyImageSharpFixed_noBase64
//         }
//       }
//     }
//     ikona_globe: file(relativePath: { eq: "ikonas/globe_56@3x.png" }) {
//       childImageSharp {
//         fixed(width: 56, height: 56) {
//           ...GatsbyImageSharpFixed_noBase64
//         }
//       }
//     }
//     ikona_latvia: file(relativePath: { eq: "ikonas/latvia_56@3x.png" }) {
//       childImageSharp {
//         fixed(width: 56, height: 56) {
//           ...GatsbyImageSharpFixed_noBase64
//         }
//       }
//     }
//   }
// `

export const izveliesQuery = graphql`
  query IzveliesLmtSekcija {
    allStrapiIzveliesLmtSekcija {
      edges {
        node {
          virsraksts__lv
          # virsraksts__ru
          virsraksts__en

          ieraksti: ieguvumi {
            virsraksts__lv
            # virsraksts__ru
            virsraksts__en
            teksts__lv
            # teksts__ru
            teksts__en
            ikona {
              childImageSharp {
                fixed(width: 56, height: 56) {
                  ...GatsbyImageSharpFixed_noBase64
                }
              }
            }
          }
        }
      }
    }
  }
`

export default function IzveliesLmtSekcija() {
  const data = useStaticQuery(izveliesQuery)
  const doc = data.allStrapiIzveliesLmtSekcija.edges.slice(0, 1).pop()
  if (!doc) return null

  return <IkonuSekcija data={doc.node} high="true" />
}
