import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import styled, { css } from "styled-components"
import { motion } from "framer-motion"
import { buttonReset } from "../core/reset"
import { rem } from "../core/utils"
import theme from "../core/theme"
import Input from "./Input"
import Checkbox from "./Checkbox"
import { translate } from "../core/helpers"
import { LanguageContext } from "../core/context/language-context"

export const piesakiesFormaQuery = graphql`
  query PiesakiesSekcijaForma {
    strapiPiesakiesSekcija {
      Forma {
        virsraksts__lv
        # virsraksts__ru
        virsraksts__en
        apstiprinajums__lv
        # apstiprinajums__ru
        apstiprinajums__en
      }
    }
  }
`

const Form = styled(motion.form)`
  background-color: #1d1d1d;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  /* min-height: ${rem(399)}; FIXME: Saistīts ar DropZone min-height */
  height: calc(100% - ${rem(83)}); /* FIXME: Pogas "Nosūtīt" augstums */

  opacity: 1;
  transition: opacity 250ms ease-out;
  z-index: 12;

  ${({ disabled }) =>
    disabled &&
    css`
      opacity: 0.5;
      pointer-events: none;
    `};

  ${({ inline }) =>
    inline &&
    css`
      box-shadow: 0px -16px 32px 0px rgba(0, 0, 0, 0.75);
      height: auto;
      top: auto;
      bottom: 83px;
      padding: 16px 12px 12px;
      margin: 0 -8px;
      width: calc(100% + 16px);

      @media (min-width: ${rem(theme.screens.custom.mobile)}) {
        margin: 0 -24px;
        padding: 24px;
        width: calc(100% + 48px);
      }

      @media (min-width: ${rem(theme.screens.sm)}) {
        box-shadow: none;
      }
    `};

  @media (min-width: ${rem(theme.screens.sm)}) {
    position: absolute;
    top: auto;
    bottom: 0;
    left: 0;
    height: auto;
    width: auto;
    margin: 0;
    margin-right: ${rem(240)};
    padding: 0;
  }

  ${({ inPopup }) =>
    inPopup &&
    css`
      position: static;
      width: auto;
      height: auto;
      box-shadow: none;

      @media (min-width: ${rem(theme.screens.sm)}) {
        position: static;
        margin-right: 0;
      }
    `};
`

const InputGroup = styled.div`
  display: flex;
  flex-direction: column;

  > input + input {
    margin-top: ${rem(12)};
  }

  @media (min-width: ${rem(theme.screens.sm)}) {
    flex-direction: row;

    > input {
      flex: 0 1 ${rem(240)};
    }

    > input + input {
      margin-top: 0;
      margin-left: ${rem(32)};
    }
  }
`

const ContentGroup = styled.div`
  margin-top: ${rem(32)};

  > div {
    display: flex;
    align-items: flex-start;

    > div /* checkbox */ {
      margin-right: ${rem(12)};
      transform: translateY(${rem(-3)});
    }
  }

  > div + div {
    margin-top: ${rem(16)};
  }
`

const Label = styled.label`
  color: #bababa;
  font-size: ${rem(12)};
  line-height: 1.3;
`

const StyledCloseButton = styled.button.attrs({ type: "button" })`
  ${buttonReset};

  cursor: pointer;
  display: flex;
  justify-content: center;

  color: #878787;
  padding: 0.5em;

  &:hover {
    color: ${theme.colors.accent};
  }

  &:focus {
    outline: 1px solid ${theme.colors.accent};
  }
`

function CloseButton({ onClick, title }) {
  return (
    <StyledCloseButton onClick={onClick} title={title}>
      <svg
        width="48"
        height="48"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M13 35l22-22M35 35L13 13"
          stroke="#BABABA"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </StyledCloseButton>
  )
}

const TopGroup = styled.div`
  padding-top: ${rem(12)};
  padding-bottom: ${rem(36)};
  position: relative;

  h4 {
    color: #e8e8e8;
    font-size: ${rem(20)};
    font-weight: 400;
    line-height: 1.3;
    margin: 0 ${rem(32)};
    text-align: center;
  }

  button {
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(${rem(24)}, ${rem(-24)});
  }

  @media (min-width: ${rem(668)}) {
    button {
      transform: translate(${rem(16)}, ${rem(-16)});
    }
  }

  @media (min-width: ${rem(theme.screens.custom.mobile)}) {
    margin: 0 ${rem(60)};

    h4 {
      margin: 0;
    }
  }
`

const formVariants = {
  initial: {
    opacity: 0,
    y: 50,
  },
  enter: {
    opacity: 1,
    y: 0,
    transition: { type: "spring", damping: 50, stiffness: 250 },
  },
}

export default function ApplicationForm({
  formRef,
  disabled,
  onClose,
  inline = false,
  inPopup = false,
}) {
  const { language, tulkojums } = React.useContext(LanguageContext)

  React.useEffect(() => {
    const form = formRef.current
    const vards = form.querySelector('input[name="vards"]')
    if (vards) {
      vards.focus()
    }
  }, [formRef])

  const data = useStaticQuery(piesakiesFormaQuery)
  const doc = data.strapiPiesakiesSekcija
  if (!doc) return null

  const forma = translate(doc.Forma, language)

  return (
    <Form
      ref={formRef}
      disabled={disabled}
      initial={inPopup ? "enter" : "initial"}
      animate="enter"
      variants={formVariants}
      inPopup={inPopup}
      inline
    >
      {!inline && (
        <TopGroup>
          <h4>{forma.virsraksts}</h4>
          <CloseButton
            onClick={() => {
              onClose && onClose()
            }}
            title={tulkojums("aizvert")}
          />
        </TopGroup>
      )}

      <InputGroup>
        <Input type="text" name="vards" placeholderText={tulkojums("vards")} />
        <Input
          type="tel"
          name="kontakttalrunis"
          placeholderText={tulkojums("kontakttalrunis")}
        />
      </InputGroup>

      <ContentGroup>
        <div>
          <Checkbox
            id="piekritu-datu-apstradei"
            name="piekritu-datu-apstradei"
          />
          <Label htmlFor="piekritu-datu-apstradei">
            {forma.apstiprinajums}
          </Label>
        </div>
        <div>
          <Checkbox id="esmu-lmt-klients" name="esmu-lmt-klients" />
          <Label htmlFor="esmu-lmt-klients">
            {tulkojums("esmu_lmt_klients")}
          </Label>
        </div>
      </ContentGroup>
    </Form>
  )
}
